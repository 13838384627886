import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { PaginationState } from "@tanstack/react-table";

import { getAllIdentities } from "@/domains/identities/services/identities.api";
import { identitiesQueryKeys } from "@/domains/identities/utils/query-keys";
import {
  getServerPagination,
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
} from "@/utils/data-browsing";

export const useGetAllIdentities = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { page, pageSize } = getServerPagination(pagination);

  const result = useQuery({
    queryKey: identitiesQueryKeys.allPaginated({ page, pageSize }),
    queryFn: () => getAllIdentities({ page, pageSize }),
  });

  return {
    ...result,
    pagination,
    setPagination,
  };
};

export const usersQueryKeys = {
  all: () => ["users"] as const,
  allPaginated: ({
    query,
    page,
    pageSize,
  }: {
    query: string;
    page: number;
    pageSize: number;
  }) => [...usersQueryKeys.all(), query, page, pageSize] as const,
  me: () => [...usersQueryKeys.all(), "me"] as const,
};

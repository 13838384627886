import { useQuery } from "@tanstack/react-query";

import { getPaymentQRCode } from "@/domains/bank-details/services/bank-details.api";
import { bankDetailsQueryKeys } from "@/domains/bank-details/utils/query-keys";

type Params = {
  bankDetailId: string;
  amount?: number;
  currency?: string;
  dueDate?: Date;
  message?: string;
  constantSymbol?: string;
  specificSymbol?: string;
  variableSymbol?: string;
};

export const useGetPaymentQrCode = ({
  bankDetailId,
  amount,
  currency,
  dueDate,
  message,
  constantSymbol,
  specificSymbol,
  variableSymbol,
}: Params) => {
  const result = useQuery({
    queryKey: bankDetailsQueryKeys.paymentQrCode({
      bankDetailId,
      amount,
      currency,
      dueDate,
      message,
      constantSymbol,
      specificSymbol,
      variableSymbol,
    }),
    queryFn: () =>
      getPaymentQRCode({
        bankDetailId,
        amount,
        currency,
        dueDate,
        message,
        constantSymbol,
        specificSymbol,
        variableSymbol,
      }),
  });

  return result;
};

export enum Route {
  login = "/login",
  register = "/register",
  resetPassword = "/reset-password",
  confirmPassword = "/confirm-password",
  profile = "/profile",
  connections = "/connections",
  loans = "/loans",
  identities = "/identities",
}

import Card from "@/components/card/card";
import CardDescription from "@/components/card/card-description";
import CardHeader from "@/components/card/card-header";
import CardTitle from "@/components/card/card-title";

type Props = {
  title: string;
  description: string;
};

export const StepHeader = ({ title, description }: Props) => {
  return (
    <Card className="p-4">
      <CardHeader className="space-y-2">
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
    </Card>
  );
};

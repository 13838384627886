import { format } from "date-fns";
import { Percent, ArrowRight, LucideIcon, CoinsIcon } from "lucide-react";
import { useTranslation } from "react-i18next";


import { Loan } from "@/domains/loans/interfaces/loan.interface";
import { convertInterestPeriodToTranslation } from "@/domains/loans/utils/convert-interest-period-to-translation";
import { formatCurrency } from "@/domains/loans/utils/format-currency";
import cn from "@/utils/cn";

type Props = {
  loan: Loan;
};

export const LoanDetails = ({ loan }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      <BaseCard
        Icon={CoinsIcon}
        title={t("loans.labels.amountDue")}
        subtitle={formatCurrency({
          amount: loan.amount,
          currency: loan.currency,
        })}
        titleColor={"text-blue-400"}
        subtitleColor={"text-blue-600"}
        backgroundColor={"bg-blue-50"}
      />
      <BaseCard
        Icon={Percent}
        title={t("loans.labels.interestRate")}
        subtitle={`${loan.interestRate}% ${convertInterestPeriodToTranslation({
          t,
          interestPeriod: loan.interestPeriod,
        })}`}
        titleColor={"text-green-400"}
        subtitleColor={"text-green-600"}
        backgroundColor={"bg-green-50"}
      />
      <TimelineCard
        fromLabel={t("loans.labels.startDate")}
        fromValue={format(loan.startDate, "d. M. yyyy")}
        toLabel={t("loans.labels.dueDate")}
        toValue={format(loan.dueDate, "d. M. yyyy")}
        labelColor={"text-gray-400"}
        valueColor={"text-gray-600"}
        backgroundColor={"bg-gray-50"}
      />
    </div>
  );
};

const BaseCard = ({
  Icon,
  title,
  subtitle,
  titleColor,
  subtitleColor,
  backgroundColor,
}: {
  Icon: LucideIcon;
  title: string;
  subtitle: string;
  titleColor: string;
  subtitleColor: string;
  backgroundColor: string;
}) => (
  <div
    className={cn(
      "col-span-full rounded-lg p-4 flex items-center space-x-4",
      backgroundColor
    )}
  >
    <Icon className={cn("w-8 h-8", titleColor)} />
    <div>
      <p className={cn("text-sm font-medium", titleColor)}>{title}</p>
      <p className={cn("text-3xl font-bold", subtitleColor)}>{subtitle}</p>
    </div>
  </div>
);

const TimelineCard = ({
  fromLabel,
  fromValue,
  toLabel,
  toValue,
  labelColor,
  valueColor,
  backgroundColor,
}: {
  fromLabel: string;
  fromValue: string;
  toLabel: string;
  toValue: string;
  labelColor: string;
  valueColor: string;
  backgroundColor: string;
}) => (
  <div
    className={cn(
      "col-span-full rounded-lg p-4 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4",
      backgroundColor
    )}
  >
    <div>
      <p className={cn("text-sm text-gray-600", labelColor)}>{fromLabel}</p>
      <p className={cn("text-lg font-semibold", valueColor)}>{fromValue}</p>
    </div>
    <ArrowRight className={cn("hidden sm:block w-6 h-6", labelColor)} />
    <div>
      <p className={cn("text-sm text-gray-600", labelColor)}>{toLabel}</p>
      <p className={cn("text-lg font-semibold", valueColor)}>{toValue}</p>
    </div>
  </div>
);

import axios from "axios";

import { handleTokens } from "@/services/interceptors/handle-tokens.interceptor";

const loansInstance = axios.create({
  baseURL: `${import.meta.env.VITE_ZONE_API_URL}/loans`,
});

handleTokens(loansInstance);

export default loansInstance;

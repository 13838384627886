import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import Button from "@/components/button/button";
import { useSendRequests } from "@/domains/connections/hooks/use-send-requests";
import { addConnectionsSchema } from "@/domains/connections/schemas/add-connections-schema";
import { FormMultiSelect } from "@/domains/core/components/form/form-multiselect";
import { useSearchUsers } from "@/domains/users/hooks/use-search-users";
import { PublicUser } from "@/domains/users/interfaces/public-user.interface";

interface Props {
  onSuccess: () => void;
}

export const ConnectionsForm = ({ onSuccess }: Props) => {
  const { t } = useTranslation();

  const form = useForm<z.infer<typeof addConnectionsSchema>>({
    resolver: zodResolver(addConnectionsSchema),
    defaultValues: {
      users: [],
    },
  });

  const { handleSendRequests, isLoading: isSendingRequests } = useSendRequests({
    onSuccess,
  });

  const onSubmit = (formData: z.infer<typeof addConnectionsSchema>) => {
    const userIds = formData?.users.map(({ id }) => id);
    handleSendRequests({ userIds });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-4">
        <FormMultiSelect<z.infer<typeof addConnectionsSchema>, PublicUser>
          control={form.control}
          name="users"
          useQuery={useSearchUsers}
          getKey={(item) => item.id}
          formatLabel={({ username, email }) => `${username} (${email})`}
          className="flex-1"
        />
        <Button type="submit" disabled={isSendingRequests} className="w-full">
          {t("connections.actions.send")}
        </Button>
      </form>
    </FormProvider>
  );
};

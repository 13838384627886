import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { FormInput } from "@/domains/core/components/form/form-input";
import { FormTextarea } from "@/domains/core/components/form/form-text-area";
import { useLoanSchema } from "@/domains/loans/schemas/loan-schema";

import { StepHeader } from "./step-header";

export const PurposeStep = () => {
  const { t } = useTranslation();
  const { step3Schema } = useLoanSchema();

  const { control } = useFormContext<z.infer<typeof step3Schema>>();

  return (
    <div className="space-y-8">
      <StepHeader
        title={t("loans.sections.create.purpose.title")}
        description={t("loans.sections.create.purpose.description")}
      />
      <div className="space-y-4">
        <FormInput
          control={control}
          name="name"
          type="text"
          label={t("loans.labels.name")}
        />
        <FormTextarea
          control={control}
          name="description"
          label={t("loans.labels.description")}
        />
      </div>
    </div>
  );
};

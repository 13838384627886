import { useState } from "react";

import { PlusCircleIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

import Button from "@/components/button/button";
import Dialog from "@/components/dialog/dialog";
import DialogContent from "@/components/dialog/dialog-content";
import DialogHeader from "@/components/dialog/dialog-header";
import DialogTitle from "@/components/dialog/dialog-title";
import DialogTrigger from "@/components/dialog/dialog-trigger";
import { OwnedIdentityForm } from "@/domains/identities/components/identity/owned-identity-form";

export const AddIdentityCard = () => {
  const { t } = useTranslation();
  const [isOpen, open] = useState<boolean>(false);

  return (
    <Dialog open={isOpen} onOpenChange={open}>
      <DialogTrigger asChild>
        <Button className="flex size-full cursor-pointer flex-row items-center justify-center rounded-xl bg-gradient-to-br from-indigo-900 to-indigo-700 text-white shadow-lg transition-all duration-300 hover:scale-105 hover:shadow-2xl">
          <div className="flex flex-col items-start">
            <h2 className="text-2xl font-bold">
              {t("loans.sections.create.participants.addIdentity.ctaTitle")}
            </h2>
            <p className="text-sm">
              {t("loans.sections.create.participants.addIdentity.ctaSubtitle")}
            </p>
          </div>
          <PlusCircleIcon className="ml-4 size-16" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t("identity.actions.createIdentity")}</DialogTitle>
        </DialogHeader>
        <OwnedIdentityForm onSuccess={() => open(false)} />
      </DialogContent>
    </Dialog>
  );
};

import { ArrowLeftIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

import Button from "@/components/button/button";
import Column from "@/components/column";
import { AppLayout } from "@/domains/core/components/layouts/app-layout";
import { PageHeader } from "@/domains/core/components/pages/page-header";
import { LoanForm } from "@/domains/loans/components/loan-form/loan-form";

export const LoanCreatePage = () => {
  const navigate = useNavigate();

  return (
    <AppLayout
      header={
        <PageHeader>
          <Button variant="ghost" onClick={() => navigate(-1)}>
            <ArrowLeftIcon />
          </Button>
        </PageHeader>
      }
    >
      <Column className="max-w-3xl gap-4">
        <LoanForm />
      </Column>
    </AppLayout>
  );
};

import { LogOutIcon, TrashIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

import Button from "@/components/button/button";
import Column from "@/components/column";
import { useSignout } from "@/domains/auth/hooks/use-signout";
import { AppLayout } from "@/domains/core/components/layouts/app-layout";
import { BankDetailSection } from "@/domains/users/components/bank-detail-section";
import { IdentitySection } from "@/domains/users/components/identity-section";
import { ProfileHeader } from "@/domains/users/components/profile-header";
import { ProfileSection } from "@/domains/users/components/profile-section";

export const ProfilePage = () => {
  const { t } = useTranslation();

  const { handleSignout } = useSignout();

  return (
    <AppLayout header={<ProfileHeader />}>
      <Column className="max-w-3xl gap-4">
        <ProfileSection />
        <IdentitySection />
        <BankDetailSection />

        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <Button
            className="w-full sm:w-auto"
            variant="outline"
            onClick={() => handleSignout()}
          >
            <LogOutIcon className="mr-2 size-4" />
            {t("profile.actions.signout")}
          </Button>
          <Button className="w-full sm:w-auto" variant="destructive" disabled>
            <TrashIcon className="mr-2 size-4" />
            {t("profile.actions.deleteAccount")}
          </Button>
        </div>
      </Column>
    </AppLayout>
  );
};

import { useEffect, useState } from "react";

import { useRegister } from "./use-register";
import { useCreateIdentity } from "../../identities/hooks/use-create-identity";

type Params = {
  username: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  address?: string;
};

export const useCreateAccount = () => {
  const [params, setParams] = useState<Params | null>(null);

  const { handleCreateIdentity, isLoading: isCreatingIdentity } =
    useCreateIdentity({});

  const { handleRegister, isLoading: isRegistering } = useRegister({});

  useEffect(() => {
    if (!params) return;

    handleRegister(params, { onSuccess: () => handleCreateIdentity(params) });
  }, [handleCreateIdentity, handleRegister, params]);

  const handleAccountCreate = async (params: Params) => {
    setParams(params);
  };

  return {
    handleAccountCreate,
    isLoading: isRegistering || isCreatingIdentity,
  };
};

import axios from "axios";

import { handleTokens } from "@/services/interceptors/handle-tokens.interceptor";

const bankDetailsInstance = axios.create({
  baseURL: `${import.meta.env.VITE_ZONE_API_URL}/bank-details`,
});

handleTokens(bankDetailsInstance);

export default bankDetailsInstance;

import { useMemo, useRef } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { defineStepper } from "@stepperize/react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import Button from "@/components/button/button";
import Separator from "@/components/separator";
import { ErrorSummary } from "@/domains/core/components/form/form-error-summary";
import { useCreateLoan } from "@/domains/loans/hooks/use-create-loan";
import { useLoanSchema } from "@/domains/loans/schemas/loan-schema";

import { CreditorsStep } from "./creditors-step";
import { DebtorsStep } from "./debtors-step";
import { PurposeStep } from "./purpose-step";
import { SummaryComponent } from "./summary-step";
import { TermsStep } from "./terms-step";
import { Currency } from "../../../../common/enums/currency.enum";
import { InterestPeriod } from "../../../../common/enums/interest-period.enum";

enum STEP {
  CREDITORS = "creditors",
  DEBTORS = "debtors",
  PURPOSE = "purpose",
  TERMS = "terms",
  SUMMARY = "summary",
}

const { steps, useStepper } = defineStepper(
  {
    index: 0,
    id: STEP.CREDITORS,
  },
  {
    index: 1,
    id: STEP.DEBTORS,
  },
  {
    index: 2,
    id: STEP.PURPOSE,
  },
  {
    index: 3,
    id: STEP.TERMS,
  },
  {
    index: 4,
    id: STEP.SUMMARY,
  }
);

export const LoanForm = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLFormElement>(null);
  const stepper = useStepper();

  const { step1Schema, step2Schema, step3Schema, step4Schema, fullSchema } =
    useLoanSchema();

  const { handleCreateLoan, isLoading: isCreatingLoan } = useCreateLoan({});

  const currentSchema = useMemo(() => {
    switch (stepper.current.id) {
      case STEP.CREDITORS:
        return step1Schema;
      case STEP.DEBTORS:
        return step2Schema;
      case STEP.PURPOSE:
        return step3Schema;
      case STEP.TERMS:
        return step4Schema;
      case STEP.SUMMARY:
        return fullSchema;
      default:
        return fullSchema;
    }
  }, [step1Schema, step2Schema, step3Schema, step4Schema, fullSchema, stepper]);

  const form = useForm<z.infer<typeof fullSchema>>({
    mode: "onTouched",
    resolver: zodResolver(currentSchema),
    defaultValues: {
      amount: 0,
      interestRate: 0,
      interestPeriod: InterestPeriod.ANNUALLY,
      currency: Currency.CZK,
      debtors: [],
      creditors: [],
    },
  });

  const onSubmit = (formData: z.infer<typeof fullSchema>) => {
    handleCreateLoan(formData);
  };

  const handlePrev = async () => {
    stepper.prev();
    scrollToTop();
  };

  const handleNext = async () => {
    const isValid = await form.trigger();

    if (isValid) {
      stepper.next();
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const getStepLabel = (step: STEP) => {
    switch (step) {
      case STEP.DEBTORS:
        return t("loans.sections.create.stepper.debtors");
      case STEP.CREDITORS:
        return t("loans.sections.create.stepper.creditors");
      case STEP.PURPOSE:
        return t("loans.sections.create.stepper.purpose");
      case STEP.TERMS:
        return t("loans.sections.create.stepper.terms");
      case STEP.SUMMARY:
        return t("loans.sections.create.stepper.summary");
      default:
        return "";
    }
  };

  return (
    <FormProvider {...form}>
      <form
        ref={ref}
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col gap-8"
      >
        <nav className="w-full" aria-label="Progress">
          <ol className="flex w-full items-center justify-between">
            {steps.map((step, index) => (
              <li
                key={step.id}
                className={`flex items-center ${
                  index !== steps.length - 1 ? "w-full" : ""
                }`}
              >
                <div className="relative flex flex-col items-center">
                  <div
                    className={`flex size-8 items-center justify-center rounded-full border-2 transition-colors duration-200 sm:size-10 ${
                      index < stepper.current.index
                        ? "border-green-600 bg-green-100 text-green-600"
                        : index === stepper.current.index
                        ? "border-blue-600 bg-blue-100 text-blue-600"
                        : "border-gray-300 bg-white text-gray-500"
                    }`}
                  >
                    <span className="text-xs font-semibold sm:text-sm">
                      {index + 1}
                    </span>
                  </div>
                  <span
                    className={`mt-2 hidden text-center text-xs font-medium sm:block sm:text-sm ${
                      index < stepper.current.index
                        ? "text-green-600"
                        : index === stepper.current.index
                        ? "text-blue-600"
                        : "text-gray-500"
                    }`}
                  >
                    {getStepLabel(step.id as STEP)}
                  </span>
                </div>
                {index !== steps.length - 1 && (
                  <div
                    className="mx-1 h-0.5 w-full bg-gray-200 sm:mx-2"
                    aria-hidden="true"
                  />
                )}
              </li>
            ))}
          </ol>
        </nav>
        <div>
          {stepper.switch({
            debtors: () => <DebtorsStep />,
            creditors: () => <CreditorsStep />,
            purpose: () => <PurposeStep />,
            terms: () => <TermsStep />,
            summary: () => <SummaryComponent />,
          })}
        </div>
        <div className="flex w-full flex-col gap-2 bg-white">
          <Separator />
          <ErrorSummary errors={form.formState.errors} />
          <div className="flex items-center justify-between p-4">
            <Button
              type="button"
              variant="outline"
              onClick={handlePrev}
              disabled={stepper.isFirst}
            >
              {t("common.actions.back")}
            </Button>
            {!stepper.isLast && (
              <Button type="button" onClick={handleNext}>
                {t("common.actions.continue")}
              </Button>
            )}
            {stepper.isLast && (
              <Button type="submit" disabled={isCreatingLoan}>
                {t("loans.actions.createLoan")}
              </Button>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

import { cva } from "class-variance-authority";

const variants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-transparent bg-primary text-white",
        secondary: "border-transparent bg-secondary text-white",
        destructive: "border-transparent bg-destructive text-white",
        success: "border-transparent bg-green-500 text-white",
        pending: "border-transparent bg-yellow-500 text-white",
        info: "border-transparent bg-blue-500 text-white",
        outline: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export default variants;

import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { AuthResponse } from "@/domains/auth/interfaces/auth-response.interface";
import { login } from "@/domains/auth/services/auth.api";
import { getLocale } from "@/domains/core/hooks/use-settings-store";
import { useProfileUpdate } from "@/domains/users/hooks/use-update-profile";

import { useAuthStore } from "./use-auth-store";
import { useErrorTranslation } from "../../../common/hooks/use-error-translation";

export const useLogin = () => {
  const { t } = useTranslation();
  const { translateError } = useErrorTranslation();
  const { handleProfileUpdate } = useProfileUpdate({});
  const { setRefreshToken, setAccessToken } = useAuthStore();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: async ({
      identifier,
      password,
    }: {
      identifier: string;
      password: string;
    }) => {
      return login({ identifier, password });
    },
    onSuccess: (response: AuthResponse) => {
      setRefreshToken(response.refreshToken);
      setAccessToken(response.accessToken);

      const locale = getLocale();
      handleProfileUpdate({ locale });

      setTimeout(() => {}, 1500);

      navigate(0);
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      toast.error(t("common.toasts.general.failure.title"), {
        description: translateError(error),
      });
    },
  });

  return {
    handleLogin: mutate,
    isLoading: isPending,
  };
};

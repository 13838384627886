import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { z } from "zod";

import { passwordSchema } from "../../../common/schemas/password";

export const useNewPasswordSchema = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return z
      .object({
        password: passwordSchema({ t }),
        confirmPassword: z.string({
          required_error: t("common.errors.fieldRequired", {
            field: t("profile.labels.confirmPassword"),
          }),
        }),
      })
      .refine((data) => data.password === data.confirmPassword, {
        message: t("profile.errors.passwordsMustMatch"),
        path: ["confirmPassword"],
      });
  }, [t]);
};

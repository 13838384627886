import { TFunction } from "i18next";

import { ErrorCode } from "./errors.enum";

type Params = {
  errorCode: ErrorCode;
  t: TFunction<"translation", undefined>;
};

export const errorCodeToTranslation = ({ errorCode, t }: Params) => {
  switch (errorCode) {
    // GENERIC
    case ErrorCode.VALIDATION_ERROR:
      return t("api.errors.validationError");

    // AUTH
    case ErrorCode.AUTH_INVALID_CREDENTIALS:
      return t("api.errors.auth.invalidCredentials");
    case ErrorCode.AUTH_RESET_TOKEN_EXPIRED:
      return t("api.errors.auth.resetTokenExpired");
    case ErrorCode.AUTH_RESET_TOKEN_INVALID:
      return t("api.errors.auth.resetTokenInvalid");

    // IDENTITY
    case ErrorCode.IDENTITY_CONFLICT:
      return t("api.errors.identity.conflict");
    case ErrorCode.IDENTITY_NOT_FOUND:
      return t("api.errors.identity.notFound");

    // LOAN
    case ErrorCode.LOAN_NO_VALID_LOAN:
      return t("api.errors.loan.noValidLoan");
    case ErrorCode.LOAN_NOT_FOUND:
      return t("api.errors.loan.notFound");

    // TRANSACTION
    case ErrorCode.TRANSACTION_NOT_FOUND:
      return t("api.errors.transaction.notFound");
    case ErrorCode.TRANSACTION_EXCEEDING_AMOUNT:
      return t("api.errors.transaction.exceedingAmount");
    case ErrorCode.TRANSACTION_NOT_PART_OF:
      return t("api.errors.transaction.notPartOf");

    // CONFIRMATION
    case ErrorCode.CONFIRMATION_ALREADY_EXISTS:
      return t("api.errors.confirmation.alreadyExists");

    // CONNECTION
    case ErrorCode.CONNECTION_NO_VALID_USERS:
      return t("api.errors.connection.noValidUsers");

    // BANK DETAILS
    case ErrorCode.BANK_DETAIL_INVALID_IBAN:
      return t("api.errors.bankDetail.invalidIban");

    // USER
    case ErrorCode.USER_IDENTIFIER_CONFLICT:
      return t("api.errors.user.identifierConflict");
    case ErrorCode.USER_NOT_FOUND:
      return t("api.errors.user.notFound");

    default:
      return t("api.errors.unknown");
  }
};

import { TFunction } from "i18next";
import { z } from "zod";

type Params = {
  t: TFunction<"translation", undefined>;
};

export const usernameSchema = ({ t }: Params) => {
  return z
    .string({
      required_error: t("common.errors.fieldRequired", {
        field: t("profile.labels.username"),
      }),
    })
    .min(4, {
      message: t("common.errors.fieldTooShort", {
        field: t("profile.labels.username"),
        count: 4,
      }),
    })
    .regex(/^[A-Za-z][A-Za-z0-9_]*$/, {
      message: t("profile.errors.usernameInvalidFormat"),
    });
};

import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Control, FieldValues, Path } from "react-hook-form";

import Button from "@/components/button/button";
import Calendar from "@/components/calendar/calendar";
import FormControl from "@/components/form/form-control";
import FormField from "@/components/form/form-field";
import FormItem from "@/components/form/form-item";
import FormMessage from "@/components/form/form-message";
import Label from "@/components/label";
import Popover from "@/components/popover/popover";
import PopoverContent from "@/components/popover/popover-content";
import PopoverTrigger from "@/components/popover/popover-trigger";
import cn from "@/utils/cn";

interface Props<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  label?: string;
  className?: string;
}

export const FormDatePicker = <TFieldValues extends FieldValues>({
  control,
  name,
  label = "",
  className = "",
}: Props<TFieldValues>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          {label && <Label>{label}</Label>}
          <FormControl>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !field.value && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 size-4" />
                  {field.value && format(new Date(field.value), "d. M. yyyy")}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  showOutsideDays
                  fixedWeeks
                  selected={field.value}
                  onSelect={field.onChange}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

import { Check, X } from "lucide-react";
import { useTranslation } from "react-i18next";

import Avatar from "@/components/avatar/avatar";
import AvatarFallback from "@/components/avatar/avatar-fallback";
import Button from "@/components/button/button";
import Card from "@/components/card/card";
import CardContent from "@/components/card/card-content";
import Column from "@/components/column";
import { useGetReceivedRequests } from "@/domains/connections/hooks/use-get-received-requests";
import { useSendRequests } from "@/domains/connections/hooks/use-send-requests";
import { Pagination } from "@/domains/core/components/pagination";

import { PendingCard } from "./pending-card";

export const PendingInvites = () => {
  const { t } = useTranslation();

  const { data, pagination, setPagination } = useGetReceivedRequests();

  const { handleSendRequests, isLoading } = useSendRequests({});

  return (
    <Column className="w-full gap-2">
      {data?.data.length === 0 && (
        <Card className="p-4">
          <CardContent>
            {t("connections.sections.pendingInvites.noInvites")}
          </CardContent>
        </Card>
      )}
      {data?.data.map((user) => (
        <PendingCard
          avatar={
            <Avatar className="size-12">
              <AvatarFallback value={user.username} />
            </Avatar>
          }
          title={
            <div className="text-lg font-medium">
              {t("connections.sections.pendingInvites.cardTitle", {
                username: user.username,
              })}
            </div>
          }
          subtitle={
            <div className="text-sm text-muted-foreground">{user.email}</div>
          }
          actions={[
            <Button
              size="sm"
              variant="default"
              onClick={() => handleSendRequests({ userIds: [user.id] })}
              disabled={isLoading}
            >
              <Check className="size-4 sm:mr-2" />
              <span className="hidden sm:inline">
                {t("connections.actions.accept")}
              </span>
            </Button>,
            <Button size="sm" variant="destructive" disabled={true}>
              <X className="size-4 sm:mr-2" />
              <span className="hidden sm:inline">
                {t("connections.actions.decline")}
              </span>
            </Button>,
          ]}
        />
      ))}
      <Pagination
        pagination={pagination}
        setPagination={setPagination}
        total={data?.total}
      />
    </Column>
  );
};

import cn from "@/utils/cn";

type Props = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
};

export const PageHeader = ({ title, subtitle, className, children }: Props) => {
  return (
    <>
      <div
        className={cn(
          "flex items-center justify-between h-16 px-6 py-3 border-b md:sticky top-0 bg-white z-10",
          className
        )}
      >
        {title}
        {children}
      </div>
      {subtitle && <div className="px-6 py-3">{subtitle}</div>}
    </>
  );
};

import * as React from "react";

import cn from "@/utils/cn";

import Avatar from "./avatar";
import AvatarFallback from "./avatar-fallback";
import AvatarImage from "./avatar-image";

export interface AvatarStackProps extends React.HTMLAttributes<HTMLDivElement> {
  avatars: { url?: string; fallback?: string }[];
  maxItems?: number;
}

const AvatarStack = ({
  className,
  avatars,
  maxItems = 4,
  ...props
}: AvatarStackProps) => {
  const shownAvatars = avatars.slice(0, maxItems);

  return (
    <div
      className={cn("flex overflow-hidden -space-x-2", className)}
      {...props}
    >
      {shownAvatars.map(({ url, fallback }) => (
        <Avatar key={fallback} className="size-8 ring-2 ring-white">
          <AvatarImage src={url} />
          <AvatarFallback value={fallback} className="text-xs" />
        </Avatar>
      ))}

      {shownAvatars.length < avatars.length && (
        <Avatar key={"+X"} className="size-8 ring-2 ring-white">
          <AvatarFallback
            value={`+${avatars.length - shownAvatars.length}`}
            className="text-xs"
          />
        </Avatar>
      )}
    </div>
  );
};

export default AvatarStack;

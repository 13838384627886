import { useMemo } from "react";

import { format } from "date-fns";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useLoanSchema } from "@/domains/loans/schemas/loan-schema";
import { convertToAnnualInterestRate } from "@/domains/loans/utils/convert-to-annual-interest-rate";
import { formatCurrency } from "@/domains/loans/utils/format-currency";
import { getFormattedDuration } from "@/domains/loans/utils/get-formatted-duration";
import { getInterestAmount } from "@/domains/loans/utils/get-interest-amount";

export const TermsSummary = () => {
  const { t } = useTranslation();
  const { step4Schema } = useLoanSchema();

  const { watch } = useFormContext<z.infer<typeof step4Schema>>();

  const formValues = watch();

  const loanDetails = useMemo(() => {
    const { amount, interestRate, interestPeriod, startDate, dueDate } =
      formValues;

    const annualInterestRate = convertToAnnualInterestRate({
      interestRate,
      interestPeriod,
    });

    const interest = getInterestAmount({
      amount,
      interestRate,
      interestPeriod,
      startDate,
      dueDate,
    });
    const totalAmount = amount + interest;

    const duration = getFormattedDuration({
      t,
      interestPeriod,
      startDate,
      dueDate,
    });

    return {
      totalAmount,
      interest,
      annualInterestRate,
      duration,
    };
  }, [formValues, t]);

  const { totalAmount, interest, annualInterestRate, duration } = loanDetails;

  return (
    <div className="rounded-lg bg-primary p-4 text-white shadow-md">
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <SummaryItem
            label={t("loans.labels.amountDue")}
            value={formatCurrency({
              amount: totalAmount,
              currency: formValues.currency,
            })}
          />
          <SummaryItem
            label={t("loans.labels.interestRate")}
            value={`${annualInterestRate}% ${t(
              "loans.enums.interestPeriod.annually"
            )}`}
          />
          <SummaryItem
            label={t("loans.labels.interestAmount")}
            value={formatCurrency({
              amount: interest,
              currency: formValues.currency,
            })}
          />
          <SummaryItem label={t("loans.labels.periodCount")} value={duration} />
          <SummaryItem
            label={t("loans.labels.startDate")}
            value={
              formValues.startDate
                ? format(formValues.startDate, "d. M. yyyy")
                : "-"
            }
          />
          <SummaryItem
            label={t("loans.labels.dueDate")}
            value={
              formValues.dueDate
                ? format(formValues.dueDate, "d. M. yyyy")
                : "-"
            }
          />
        </div>
      </div>
    </div>
  );
};

const SummaryItem = ({ label, value }: { label: string; value: string }) => (
  <div className="text-center">
    <p className="text-sm opacity-80">{label}</p>
    <p className="text-xl font-bold">{value}</p>
  </div>
);

import Column from "@/components/column";
import Separator from "@/components/separator";
import { ConnectionsHeader } from "@/domains/connections/components/connections/connections-header";
import { ConnectionsList } from "@/domains/connections/components/connections/connections-list";
import { PendingConnections } from "@/domains/connections/components/connections/pending-connections";
import { AppLayout } from "@/domains/core/components/layouts/app-layout";

export const ConnectionsPage = () => {
  return (
    <AppLayout header={<ConnectionsHeader />}>
      <Column className="max-w-3xl gap-2">
        <PendingConnections />
        <Separator className="my-4" />
        <ConnectionsList />
      </Column>
    </AppLayout>
  );
};

import { useTranslation } from "react-i18next";

import { TransactionTypeIcon } from "@/domains/loans/components/transactions/transaction-type-icon";
import { LoanTransaction } from "@/domains/loans/interfaces/loan-transaction";
import { Loan } from "@/domains/loans/interfaces/loan.interface";
import { convertTransactionTypeToTranslation } from "@/domains/loans/utils/convert-transaction-type-to-translation";
import { formatCurrency } from "@/domains/loans/utils/format-currency";

import { TransactionCardParticipants } from "./transaction-card-participants";

type Props = {
  loan: Loan;
  transaction: LoanTransaction;
};

export const TransactionCardContent = ({ loan, transaction }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center justify-between gap-2">
        <TransactionTypeIcon transaction={transaction} />
        <div className="flex flex-col">
          <p className="font-medium">
            {convertTransactionTypeToTranslation({
              t,
              transactionType: transaction.type,
            })}
          </p>
          <TransactionCardParticipants transaction={transaction} />
        </div>
      </div>
      <div className="flex flex-col items-end">
        <div className={"text-2xl font-bold"}>
          {formatCurrency({
            amount: transaction.amount,
            currency: loan.currency,
          })}
        </div>
      </div>
    </div>
  );
};

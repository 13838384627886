import queryString from "query-string";

import { LoanAgreement } from "@/domains/loans/interfaces/loan-agreement";
import { LoanTransaction } from "@/domains/loans/interfaces/loan-transaction";
import { Loan } from "@/domains/loans/interfaces/loan.interface";
import { TransactionsSummary } from "@/domains/loans/interfaces/transactions-summary";
import { PaginatedData } from "@/interfaces/paginated-data.interface";
import { Pagination } from "@/utils/data-browsing";

import loansInstance from "./loans.instance";
import { Currency } from "../../../common/enums/currency.enum";
import { InterestPeriod } from "../../../common/enums/interest-period.enum";

export const createLoan = async (data: {
  name: string;
  description?: string;
  amount: number;
  currency: Currency;
  interestRate: number;
  interestPeriod: InterestPeriod;
  startDate: string;
  dueDate: string;
  debtorIds: string[];
  creditorIds: string[];
}) => {
  return loansInstance.post<Loan>(`/`, data);
};

export const getMyLoans = async ({
  pagination,
  sortBy,
}: {
  pagination: Pagination;
  sortBy: string | null;
}): Promise<PaginatedData<Loan>> => {
  const query = queryString.stringify(
    {
      page: pagination.page,
      pageSize: pagination.pageSize,
      sortBy,
    },
    { skipEmptyString: true }
  );
  const response = await loansInstance.get<PaginatedData<Loan>>(`/me?${query}`);
  return response.data;
};

export const getLoan = async ({
  loanId,
}: {
  loanId: string;
}): Promise<Loan> => {
  const response = await loansInstance.get<Loan>(`/${loanId}`);
  return response.data;
};

export const getLoanAgreements = async ({
  loanId,
}: {
  loanId: string;
}): Promise<PaginatedData<LoanAgreement>> => {
  const response = await loansInstance.get<PaginatedData<LoanAgreement>>(
    `/${loanId}/agreements`
  );
  return response.data;
};

export const acceptLoan = async ({
  loanId,
}: {
  loanId: string;
}): Promise<void> => {
  await loansInstance.post(`/${loanId}/accept`);
};

export const declineLoan = async ({
  loanId,
}: {
  loanId: string;
}): Promise<void> => {
  await loansInstance.post(`/${loanId}/decline`);
};

export const addTransaction = async ({
  amount,
  loanId,
  senderId,
  receiverId,
}: {
  amount: number;
  loanId: string;
  senderId: string;
  receiverId: string;
}) => {
  await loansInstance.post(`/${loanId}/transactions`, {
    amount,
    senderId,
    receiverId,
  });
};

export const getLoanTransactions = async ({
  loanId,
  page,
  pageSize,
}: {
  loanId: string;
  page: number;
  pageSize: number;
}): Promise<PaginatedData<LoanTransaction>> => {
  const query = queryString.stringify({ page, pageSize });

  const response = await loansInstance.get<PaginatedData<LoanTransaction>>(
    `/${loanId}/transactions?${query}`
  );
  return response.data;
};

export const getTransactionsSummary = async ({
  loanId,
}: {
  loanId: string;
}): Promise<TransactionsSummary> => {
  const response = await loansInstance.get<TransactionsSummary>(
    `/${loanId}/transactions/summary`
  );

  return response.data;
};

export const confirmTransaction = async ({
  transactionId,
  loanId,
}: {
  transactionId: string;
  loanId: string;
}) => {
  await loansInstance.post(`/${loanId}/transactions/${transactionId}/confirm`);
};

export const cancelTransaction = async ({
  transactionId,
  loanId,
}: {
  transactionId: string;
  loanId: string;
}) => {
  await loansInstance.post(`/${loanId}/transactions/${transactionId}/cancel`);
};

import queryString from "query-string";

import { PublicUser } from "@/domains/users/interfaces/public-user.interface";
import { User } from "@/domains/users/interfaces/user.interface";
import { PaginatedData } from "@/interfaces/paginated-data.interface";

import usersInstance from "./users.instance";

export const getMe = async (): Promise<User> => {
  const response = await usersInstance.get<User>(`/me`);
  return response.data;
};

export const updateMe = async ({
  username,
  locale,
}: {
  username?: string;
  locale?: string;
}): Promise<void> => {
  await usersInstance.patch<User>(`/me`, { username, locale });
};

export const searchUsers = async ({
  query,
  page,
  pageSize,
}: {
  query: string;
  page?: number;
  pageSize?: number;
}): Promise<PaginatedData<PublicUser>> => {
  const queryParams = queryString.stringify({
    query,
    page,
    pageSize,
  });
  const response = await usersInstance.get<PaginatedData<PublicUser>>(
    `/?${queryParams}`
  );
  return response.data;
};

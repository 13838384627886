import { LucideIcon } from "lucide-react";
import { FieldValues, Control, Path } from "react-hook-form";

import FormControl from "@/components/form/form-control";
import FormField from "@/components/form/form-field";
import FormItem from "@/components/form/form-item";
import FormMessage from "@/components/form/form-message";
import Input from "@/components/input";
import Label from "@/components/label";
import cn from "@/utils/cn";

interface Props<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  type: React.HTMLInputTypeAttribute;
  step?: number;
  label?: string;
  placeholder?: string;
  icon?: LucideIcon;
  className?: string;
}

export const FormInput = <TFieldValues extends FieldValues>({
  control,
  name,
  type,
  step,
  label,
  placeholder,
  icon,
  className,
}: Props<TFieldValues>) => {
  const Icon = icon;

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn("w-full relative", className)}>
          {label && <Label>{label}</Label>}
          {Icon && (
            <div className="pointer-events-none absolute left-3 top-9 flex items-center">
              <Icon className="size-4 text-gray-500" />
            </div>
          )}
          <FormControl>
            <Input
              {...field}
              type={type}
              step={step}
              placeholder={placeholder}
              onChange={(e) => {
                switch (type) {
                  case "number":
                    field.onChange(e.target.valueAsNumber);
                    break;
                  default:
                    field.onChange(e.target.value);
                    break;
                }
              }}
              className={cn(icon ? "pl-8" : "", "w-full")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

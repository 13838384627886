import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { AuthLayout } from "@/domains/core/components/layouts/auth-layout";

import { Route } from "../../../common/enums/route.enum";
import { NewPasswordForm } from "../components/new-password-form";

export const NewPasswordPage = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = queryString.parse(location.search);
  const tokenId =
    typeof queryParams.tokenId === "string" ? queryParams.tokenId : null;

  if (!tokenId) {
    navigate(Route.login);
    toast.error(t("common.toasts.general.failure.title"));
    return;
  }

  return (
    <AuthLayout
      title={t("profile.sections.confirmPassword.title")}
      description={t("profile.sections.confirmPassword.description")}
    >
      <NewPasswordForm tokenId={tokenId} />
    </AuthLayout>
  );
};

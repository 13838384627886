import { MapPinIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { FormInput } from "../../../core/components/form/form-input";
import { useRegisterSchema } from "../../hooks/use-register-schema";

export const IdentityInputs = () => {
  const { t } = useTranslation();
  const { step1Schema } = useRegisterSchema();
  const { control } = useFormContext<z.infer<typeof step1Schema>>();

  return (
    <div className="w-full">
      <div className="flex w-full space-x-4">
        <FormInput
          className="w-1/2"
          control={control}
          name="firstName"
          type="text"
          label={t("identity.labels.firstname")}
        />
        <FormInput
          className="w-1/2"
          control={control}
          name="lastName"
          type="text"
          label={t("identity.labels.lastname")}
        />
      </div>
      <FormInput
        icon={MapPinIcon}
        control={control}
        name="address"
        type="text"
        label={t("identity.labels.addressOptional")}
      />
    </div>
  );
};

import { useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import Button from "@/components/button/button";
import { FormInput } from "@/domains/core/components/form/form-input";
import { useCreateIdentity } from "@/domains/identities/hooks/use-create-identity";
import { useGetMyIdentity } from "@/domains/identities/hooks/use-get-my-identity";
import { useIdentityFormSchema } from "@/domains/identities/hooks/use-identity-form-schema";
import { useUpdateIdentity } from "@/domains/identities/hooks/use-update-identity";

interface Props {
  onSuccess?: () => void;
}

export const IdentityForm = ({ onSuccess }: Props) => {
  const { t } = useTranslation();

  const { data: identity } = useGetMyIdentity();
  const { handleCreateIdentity, isLoading: isCreatingIdentity } =
    useCreateIdentity({ onSuccess });
  const { handleUpdatingIdentity, isLoading: isUpdatingIdentity } =
    useUpdateIdentity({ onSuccess });

  const schema = useIdentityFormSchema();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: identity?.firstName,
      lastName: identity?.lastName,
      address: identity?.address,
    },
  });

  const onSubmit = ({
    firstName,
    lastName,
    address,
  }: z.infer<typeof schema>) => {
    if (!identity) {
      handleCreateIdentity({ firstName, lastName, address });
    } else {
      handleUpdatingIdentity({ firstName, lastName, address });
    }
  };

  useEffect(() => {
    if (!identity) return;

    form.reset({
      firstName: identity.firstName,
      lastName: identity.lastName,
      address: identity.address,
    });
  }, [form, identity]);

  const isLoading = isCreatingIdentity || isUpdatingIdentity;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex w-full space-x-4">
          <FormInput
            control={form.control}
            name={"firstName"}
            type="text"
            label={t("identity.labels.firstname")}
            className="flex-1"
          />
          <FormInput
            control={form.control}
            name={"lastName"}
            type="text"
            label={t("identity.labels.lastname")}
            className="flex-1"
          />
        </div>
        <FormInput
          control={form.control}
          name={"address"}
          type="text"
          label={t("identity.labels.address")}
        />
        <Button type="submit" disabled={isLoading}>
          {isLoading && <Loader2 className="mr-2 size-4 animate-spin" />}
          {t("common.actions.saveChanges")}
        </Button>
      </form>
    </FormProvider>
  );
};

import { Link } from "react-router-dom";

import LogoWithText from "@/assets/logo-with-text.svg";

import { NavigationContent } from "./navigation-content";

export const DesktopNavigation = () => {
  return (
    <div className="flex h-full w-[280px] flex-col">
      <div className="h-16 border-b px-6 py-3">
        <Link to="/">
          <LogoWithText className="h-10 text-primary" />
          <span className="sr-only">Zone</span>
        </Link>
      </div>
      <NavigationContent />
    </div>
  );
};

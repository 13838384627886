import { InterestPeriod } from "../../../common/enums/interest-period.enum";

type Params = {
  interestRate?: number;
  interestPeriod?: InterestPeriod;
};

export const convertToAnnualInterestRate = ({
  interestRate = 0,
  interestPeriod = InterestPeriod.ANNUALLY,
}: Params) => {
  let calculatedInterestRate = 0;

  switch (interestPeriod) {
    case InterestPeriod.DAILY:
      calculatedInterestRate = interestRate * 365;
      break;
    case InterestPeriod.WEEKLY:
      calculatedInterestRate = interestRate * 52;
      break;
    case InterestPeriod.MONTHLY:
      calculatedInterestRate = interestRate * 12;
      break;
    case InterestPeriod.QUARTERLY:
      calculatedInterestRate = interestRate * 4;
      break;
    case InterestPeriod.ANNUALLY:
      calculatedInterestRate = interestRate;
      break;
    default:
      calculatedInterestRate = interestRate;
      break;
  }

  return Number(calculatedInterestRate.toFixed(2));
};

import { UserRoundIcon, MailIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { FormInput } from "../../../core/components/form/form-input";
import { useRegisterSchema } from "../../hooks/use-register-schema";

export const AccountInputs = () => {
  const { t } = useTranslation();
  const { step2Schema } = useRegisterSchema();
  const { control } = useFormContext<z.infer<typeof step2Schema>>();

  return (
    <div>
      <FormInput
        icon={UserRoundIcon}
        control={control}
        label={t("profile.labels.username")}
        type="text"
        name="username"
      />
      <FormInput
        icon={MailIcon}
        control={control}
        label={t("profile.labels.email")}
        name="email"
        type="email"
        placeholder="email@example.com"
      />
    </div>
  );
};

import { useEffect } from "react";

import {
  browserTracingIntegration,
  init as initSentry,
  replayIntegration,
} from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { use } from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import { i18nextPlugin } from "translation-check";

import Toast from "@/components/toast";
import TooltipProvider from "@/components/tooltip/tooltip-provider";
import { getLocale } from "@/domains/core/hooks/use-settings-store";
import { createQueryClient } from "@/utils/create-query-client";

import cs from "./locales/cs.json";
import en from "./locales/en.json";
import { router } from "./routes";

type NestedKeysLeaf<T> = T extends object
  ? {
      [K in keyof T]: `${K & string}${NestedKeysLeaf<T[K]> extends never
        ? ""
        : `.${NestedKeysLeaf<T[K]>}`}`;
    }[keyof T]
  : never;

export type TranslationKeys = NestedKeysLeaf<typeof en.translation>;

use(initReactI18next)
  .use(i18nextPlugin)
  .init({
    resources: {
      en: { ...en },
      cs: { ...cs },
    },
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: ["cs", "en"],
  });

initSentry({
  enabled: import.meta.env.VITE_SENTRY_ENABLED === "true",
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tunnel: `${import.meta.env.VITE_ZONE_API_URL}/sentry/tunnel`,
  tracesSampleRate: 0.1,
  integrations: [
    browserTracingIntegration(),
    replayIntegration({ maskAllText: false, maskAllInputs: false }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const locale = getLocale();
    i18n.changeLanguage(locale);
  }, [i18n]);

  return (
    <QueryClientProvider client={createQueryClient()}>
      <ReactQueryDevtools />
      <TooltipProvider>
        <RouterProvider router={router} />
        <Toast />
      </TooltipProvider>
    </QueryClientProvider>
  );
};

export default App;

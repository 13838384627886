import { useQuery } from "@tanstack/react-query";

import { getMyIdentity } from "@/domains/identities/services/identities.api";
import { identitiesQueryKeys } from "@/domains/identities/utils/query-keys";

export const useGetMyIdentity = () =>
  useQuery({
    queryKey: identitiesQueryKeys.me(),
    queryFn: getMyIdentity,
  });

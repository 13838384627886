import { z } from "zod";

export const addConnectionsSchema = z.object({
  users: z
    .array(
      z.object({
        id: z.string(),
        label: z.string(),
      })
    )
    .min(1, "At least one user is required"),
});

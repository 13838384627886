import { PaginationState, SortingState } from "@tanstack/react-table";

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_INDEX = DEFAULT_PAGE - 1;

export const DEFAULT_PAGE_SIZE = 20;

export interface Pagination {
  page: number;
  pageSize: number;
}

export const getServerPagination = ({
  pageIndex,
  pageSize,
}: PaginationState): Pagination => ({ page: pageIndex + 1, pageSize });

export const getServerSorting = (sorting: SortingState): string =>
  sorting.map(({ id, desc }) => `${id}:${desc ? "desc" : "asc"}`).join(",");

import { ProfilePage } from "@/domains/users/pages/profile";

import { Route } from "../common/enums/route.enum";

export const profileRoutes = [
  {
    path: Route.profile,
    element: <ProfilePage />,
  },
];

import { useTranslation } from "react-i18next";

import Badge from "@/components/badge/badge";
import { Identity } from "@/domains/identities/interfaces/identity.interface";
import { LoanAgreement } from "@/domains/loans/interfaces/loan-agreement";
import { getAgreementStatus } from "@/domains/loans/utils/get-agreement-status";

import { AgreementStatus } from "../../../../common/enums/agreement-status";

type Props = {
  identity: Identity;
  agreements: LoanAgreement[];
};

export const AgreementBadge = ({ identity, agreements }: Props) => {
  const { t } = useTranslation();

  const agreementStatus = getAgreementStatus({
    identity,
    agreements,
  });

  switch (agreementStatus) {
    case AgreementStatus.AGREED:
      return (
        <Badge variant="success" className="uppercase">
          {t("agreements.enums.agreementStatus.agreed")}
        </Badge>
      );
    case AgreementStatus.CANCELLED:
      return (
        <Badge variant="destructive" className="uppercase">
          {t("agreements.enums.agreementStatus.cancelled")}
        </Badge>
      );
    case AgreementStatus.PENDING:
      return (
        <Badge variant="pending" className="uppercase">
          {t("agreements.enums.agreementStatus.pending")}
        </Badge>
      );
    default:
      return null;
  }
};

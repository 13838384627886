import { useCallback } from "react";

import { UserPlus } from "lucide-react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Button from "@/components/button/button";
import { useGetAllIdentities } from "@/domains/identities/hooks/use-get-all-identites";
import { useGetMyIdentity } from "@/domains/identities/hooks/use-get-my-identity";

import { AddIdentityCard } from "./add-identity-card";
import { IdentityButton } from "./identity-button";
import { Route } from "../../../../../common/enums/route.enum";
import { Pagination } from "../../../../core/components/pagination";

type Participant = { id: string; fullname?: string; address?: string };

type Props = {
  name: "debtors" | "creditors";
  selectedParticipants: Participant[];
};

export const IdentitySelector = ({ name, selectedParticipants }: Props) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { data: myIdentity } = useGetMyIdentity();
  const { data: identities, pagination, setPagination } = useGetAllIdentities();

  const handleToggle = useCallback(
    ({
      id,
      fullname,
      address,
    }: {
      id: string;
      fullname: string;
      address?: string;
    }) => {
      const isItemInList = selectedParticipants.some(
        (currentItem) => currentItem.id === id
      );
      if (isItemInList) {
        const newList = selectedParticipants.filter(
          (currentItem) => currentItem.id !== id
        );
        setValue(name, newList, { shouldValidate: true });
      } else {
        setValue(name, [...selectedParticipants, { id, fullname, address }], {
          shouldValidate: true,
        });
      }
    },
    [setValue, name, selectedParticipants]
  );

  const handleAddContact = () => {
    window.open(Route.connections, "_blank");
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
        <div className="min-h-40 sm:col-span-2 md:col-span-2 lg:col-span-2">
          <Button
            className="flex size-full cursor-pointer flex-row items-center justify-center rounded-xl bg-gradient-to-br from-green-600 to-green-400 p-6 text-white shadow-lg transition-all duration-300 hover:scale-105 hover:shadow-2xl"
            onClick={handleAddContact}
          >
            <div className="flex flex-col items-start">
              <h2 className="text-2xl font-bold">
                {t("loans.sections.create.participants.addConnection.ctaTitle")}
              </h2>
              <p className="text-sm">
                {t(
                  "loans.sections.create.participants.addConnection.ctaSubtitle"
                )}
              </p>
            </div>
            <UserPlus className="ml-4 size-16" />
          </Button>
        </div>
        <div className="min-h-40 sm:col-span-2 md:col-span-2 lg:col-span-2">
          <AddIdentityCard />
        </div>
        {myIdentity && (
          <div className="h-40">
            <IdentityButton
              identity={myIdentity}
              isSelected={selectedParticipants.some(
                (currentItem) => currentItem.id === myIdentity.id
              )}
              toggle={handleToggle}
            />
          </div>
        )}
        {identities?.data?.map((identity) => (
          <div key={identity.id} className="h-40">
            <IdentityButton
              identity={identity}
              isSelected={selectedParticipants.some(
                (currentItem) => currentItem.id === identity.id
              )}
              toggle={handleToggle}
            />
          </div>
        ))}
      </div>
      <div className="flex justify-center md:justify-end">
        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          total={identities?.total}
        />
      </div>
    </div>
  );
};

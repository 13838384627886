import { ArrowRight } from "lucide-react";

import { getFullname } from "@/domains/identities/utils/get-fullname";
import { LoanTransaction } from "@/domains/loans/interfaces/loan-transaction";

import { TransactionType } from "../../../../../common/enums/transaction-type";

type Props = {
  transaction: LoanTransaction;
};

export const TransactionCardParticipants = ({ transaction }: Props) => {
  const isBetweenUsers = [TransactionType.PAYMENT].includes(transaction.type);

  if (!isBetweenUsers) {
    return null;
  }

  return (
    <div className="flex items-center text-sm text-muted-foreground">
      <span>{getFullname(transaction.sender)}</span>
      <ArrowRight className="mx-1 size-4" />
      <span>{getFullname(transaction.receiver)}</span>
    </div>
  );
};

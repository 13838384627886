import { format } from "date-fns";
import { Calendar } from "lucide-react";

import { TransactionStatusBadge } from "@/domains/loans/components/transactions/transaction-status-badge";
import { LoanTransaction } from "@/domains/loans/interfaces/loan-transaction";

type Props = {
  transaction: LoanTransaction;
};

export const TransactionCardHeader = ({ transaction }: Props) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row gap-2">
        <TransactionStatusBadge transaction={transaction} />
      </div>
      <div className="mt-2 flex items-center">
        <Calendar className="mr-1 size-4 text-gray-400" />
        <p className="text-xs text-gray-500">
          {format(transaction.createdAt, "d. M. yyyy")}
        </p>
      </div>
    </div>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import Button from "@/components/button/button";
import { FormInput } from "@/domains/core/components/form/form-input";
import { useCreateOwnedIdentity } from "@/domains/identities/hooks/use-create-owned-identity";
import { useIdentityFormSchema } from "@/domains/identities/hooks/use-identity-form-schema";

interface Props {
  onSuccess?: () => void;
}

export const OwnedIdentityForm = ({ onSuccess }: Props) => {
  const { t } = useTranslation();

  const { handleCreateOwnedIdentity, isLoading } = useCreateOwnedIdentity({
    onSuccess,
  });

  const schema = useIdentityFormSchema();
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (formData: z.infer<typeof schema>) => {
    handleCreateOwnedIdentity(formData);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-4">
        <div className="flex w-full space-x-4">
          <FormInput
            control={form.control}
            name={"firstName"}
            type="text"
            label={t("identity.labels.firstname")}
            className="flex-1"
          />
          <FormInput
            control={form.control}
            name={"lastName"}
            type="text"
            label={t("identity.labels.lastname")}
            className="flex-1"
          />
        </div>
        <FormInput
          control={form.control}
          name={"address"}
          type="text"
          label={t("identity.labels.address")}
        />
        <Button type="submit" disabled={isLoading} className="w-full">
          {t("common.actions.saveChanges")}
        </Button>
      </form>
    </FormProvider>
  );
};

import { format, parseISO, differenceInDays } from "date-fns";

import { Progress } from "@/components/progress";
import { Loan } from "@/domains/loans/interfaces/loan.interface";
import cn from "@/utils/cn";

import { LoanStatus } from "../../../common/enums/loan-status.enum";

type Props = {
  loan: Loan;
};

export const LoanTimeline = ({ loan }: Props) => {
  const start = parseISO(loan.startDate);
  const end = parseISO(loan.dueDate);
  const current = new Date();

  const totalDuration = differenceInDays(end, start);
  const currentProgress = differenceInDays(current, start);

  let progressPercentage = Math.min(
    Math.max((currentProgress / totalDuration) * 100, 0),
    100
  );

  let indicatorColor = "bg-gray-500";

  switch (loan.status) {
    case LoanStatus.PENDING:
      indicatorColor = "bg-yellow-500";
      break;
    case LoanStatus.ACTIVE:
      indicatorColor = "bg-green-500";
      break;
    case LoanStatus.PAID:
      progressPercentage = 100;
      indicatorColor = "bg-blue-500";
      break;
    case LoanStatus.CANCELLED:
    default:
      progressPercentage = 0;
      indicatorColor = "bg-primary";
      break;
  }

  if (loan.isOverdue) {
    indicatorColor = "bg-red-500";
  }

  return (
    <div className="w-full">
      <div className="mt-2 flex w-full justify-between text-xs text-gray-500">
        <span>{format(start, "d. M. yyyy")}</span>
        <span>{format(end, "d. M. yyyy")}</span>
      </div>
      <Progress
        value={progressPercentage}
        className={"h-2"}
        indicatorClassName={cn(indicatorColor)}
      />
    </div>
  );
};

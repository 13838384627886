import * as React from "react";

import * as AvatarPrimitive from "@radix-ui/react-avatar";

import cn from "@/utils/cn";

const stringToColor = ({ value }: { value: string }): string => {
  const colors = [
    "bg-green-400",
    "bg-blue-400",
    "bg-purple-400",
    "bg-indigo-400",
    "bg-teal-400",
  ];

  const charSum = value
    .split("")
    .reduce((sum, char) => sum + char.charCodeAt(0), 0);
  const index = charSum % colors.length;

  return colors[index];
};

const getInitials = ({ value }: { value: string }): string => {
  return value.substring(0, 2).toUpperCase();
};

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback> & {
    value?: string;
  }
>(({ className, value = "username", ...props }, ref) => {
  const initials = getInitials({ value });
  const bgColor = stringToColor({ value });

  return (
    <AvatarPrimitive.Fallback
      ref={ref}
      className={cn(
        `flex h-full w-full items-center justify-center rounded-full text-white`,
        bgColor,
        className
      )}
      {...props}
    >
      {initials}
    </AvatarPrimitive.Fallback>
  );
});

AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export default AvatarFallback;

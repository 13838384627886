import Card from "@/components/card/card";
import { LoanTransaction } from "@/domains/loans/interfaces/loan-transaction";
import { Loan } from "@/domains/loans/interfaces/loan.interface";

import { TransactionCardContent } from "./transaction-card-content";
import { TransactionCardFooter } from "./transaction-card-footer";
import { TransactionCardHeader } from "./transaction-card-header";

type Props = {
  loan: Loan;
  transaction: LoanTransaction;
};

export const TransactionCard = ({ loan, transaction }: Props) => {
  return (
    <Card className="flex grow flex-col gap-4 p-4">
      <TransactionCardHeader transaction={transaction} />
      <TransactionCardContent loan={loan} transaction={transaction} />
      <TransactionCardFooter loan={loan} transaction={transaction} />
    </Card>
  );
};

import { Identity } from "@/domains/identities/interfaces/identity.interface";

import { LoanRole } from "../../../common/enums/loan-role";

type Params = {
  identityId: string;
  creditors: Identity[];
  debtors: Identity[];
};

export const getLoanRole = ({ identityId, creditors, debtors }: Params) => {
  const creditor = creditors.find((creditor) => creditor.id === identityId);
  if (creditor) {
    return LoanRole.CREDITOR;
  }

  const debtor = debtors.find((debtor) => debtor.id === identityId);
  if (debtor) {
    return LoanRole.DEBTOR;
  }

  return null;
};

import { Connection } from "@/domains/connections/interfaces/connection.interface";
import { PublicUser } from "@/domains/users/interfaces/public-user.interface";
import { PaginatedData } from "@/interfaces/paginated-data.interface";

import connectionsInstance from "./connections.instance";

export const getRequestedRequests = async ({
  page,
  pageSize,
}: {
  page: number;
  pageSize: number;
}): Promise<PaginatedData<PublicUser>> => {
  const response = await connectionsInstance.get<PaginatedData<PublicUser>>(
    `/requested?page=${page}&pageSize=${pageSize}`
  );
  return response.data;
};

export const getReceivedRequests = async ({
  page,
  pageSize,
}: {
  page: number;
  pageSize: number;
}): Promise<PaginatedData<PublicUser>> => {
  const response = await connectionsInstance.get<PaginatedData<PublicUser>>(
    `/received?page=${page}&pageSize=${pageSize}`
  );
  return response.data;
};

export const getConnections = async ({
  page,
  pageSize,
}: {
  page: number;
  pageSize: number;
}): Promise<PaginatedData<Connection>> => {
  const response = await connectionsInstance.get<PaginatedData<Connection>>(
    `/?page=${page}&pageSize=${pageSize}`
  );
  return response.data;
};

export const sendRequests = async ({
  userIds,
}: {
  userIds: string[];
}): Promise<void> => {
  await connectionsInstance.post("", {
    userIds,
  });
};

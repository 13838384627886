import { useState } from "react";

import { PlusCircleIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

import Button from "@/components/button/button";
import Dialog from "@/components/dialog/dialog";
import DialogContent from "@/components/dialog/dialog-content";
import DialogDescription from "@/components/dialog/dialog-description";
import DialogHeader from "@/components/dialog/dialog-header";
import DialogTitle from "@/components/dialog/dialog-title";
import DialogTrigger from "@/components/dialog/dialog-trigger";

import { ConnectionsForm } from "./connections-form";

export const CreateConnectionsCard = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button className="flex size-full cursor-pointer flex-row items-center justify-center rounded-xl bg-gradient-to-br from-indigo-900 to-indigo-700 p-6 text-white shadow-lg transition-all duration-300 hover:scale-105 hover:shadow-2xl">
          <div>
            <h2 className="text-2xl font-bold">
              {t("connections.sections.list.ctaTitle")}
            </h2>
            <p className="text-sm">
              {t("connections.sections.list.ctaSubtitle")}
            </p>
          </div>
          <PlusCircleIcon className="ml-4 size-16" />
        </Button>
      </DialogTrigger>
      <DialogContent className="mx-auto w-full max-w-xl">
        <DialogHeader>
          <DialogTitle>{t("connections.sections.dialog.title")}</DialogTitle>
          <DialogDescription>
            {t("connections.sections.dialog.description")}
          </DialogDescription>
        </DialogHeader>
        <ConnectionsForm onSuccess={() => setIsOpen(false)} />
      </DialogContent>
    </Dialog>
  );
};

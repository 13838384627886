import { LockIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { FormInput } from "../../../core/components/form/form-input";
import { useRegisterSchema } from "../../hooks/use-register-schema";

export const SecurityInputs = () => {
  const { t } = useTranslation();
  const { step3Schema } = useRegisterSchema();
  const { control } = useFormContext<z.infer<typeof step3Schema>>();

  return (
    <div>
      <FormInput
        icon={LockIcon}
        control={control}
        label={t("profile.labels.password")}
        name="password"
        type="password"
      />
      <FormInput
        icon={LockIcon}
        control={control}
        label={t("profile.labels.confirmPassword")}
        name="confirmPassword"
        type="password"
      />
    </div>
  );
};

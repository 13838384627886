import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { FormDatePicker } from "@/domains/core/components/form/form-date-picker";
import { FormInput } from "@/domains/core/components/form/form-input";
import { FormSelect } from "@/domains/core/components/form/form-select";
import { useLoanSchema } from "@/domains/loans/schemas/loan-schema";
import { convertInterestPeriodToTranslation } from "@/domains/loans/utils/convert-interest-period-to-translation";

import { StepHeader } from "./step-header";
import { TermsSummary } from "./terms-summary";
import { Currency } from "../../../../common/enums/currency.enum";
import { InterestPeriod } from "../../../../common/enums/interest-period.enum";

export const TermsStep = () => {
  const { t } = useTranslation();
  const { step4Schema } = useLoanSchema();

  const { control } = useFormContext<z.infer<typeof step4Schema>>();

  return (
    <div className="space-y-8">
      <StepHeader
        title={t("loans.sections.create.terms.title")}
        description={t("loans.sections.create.terms.description")}
      />

      <TermsSummary />

      <div className="space-y-6">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <FormInput
            control={control}
            name="amount"
            type="number"
            step={0.01}
            label={t("loans.labels.amountDue")}
          />
          <FormSelect
            control={control}
            name="currency"
            label={t("loans.labels.currency")}
            options={Object.values(Currency).map((currency) => ({
              value: currency,
              name: currency,
            }))}
          />
        </div>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <FormInput
            control={control}
            name="interestRate"
            type="number"
            step={0.01}
            label={t("loans.labels.interestRate")}
          />
          <FormSelect
            control={control}
            name="interestPeriod"
            label={t("loans.labels.interestPeriod")}
            options={Object.values(InterestPeriod).map((period) => ({
              value: period,
              name: convertInterestPeriodToTranslation({
                t,
                interestPeriod: period,
              }),
            }))}
          />
        </div>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <FormDatePicker
            control={control}
            name="startDate"
            label={t("loans.labels.startDate")}
          />
          <FormDatePicker
            control={control}
            name="dueDate"
            label={t("loans.labels.dueDate")}
          />
        </div>
      </div>
    </div>
  );
};

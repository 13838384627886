import { useQuery } from "@tanstack/react-query";

import { getMe } from "@/domains/users/services/users.api";
import { usersQueryKeys } from "@/domains/users/utils/query-keys";

export const useGetMe = () =>
  useQuery({
    queryKey: usersQueryKeys.me(),
    queryFn: () => getMe(),
  });

import { Dispatch, SetStateAction, useMemo } from "react";

import { PaginationState } from "@tanstack/react-table";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";

import Button from "@/components/button/button";

interface Props {
  total?: number;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
}

export const Pagination = ({ total = 0, pagination, setPagination }: Props) => {
  const pageCount = useMemo(() => {
    return Math.ceil(total / pagination.pageSize);
  }, [total, pagination]);

  const canGoPrevious = useMemo(() => {
    return pagination.pageIndex > 0;
  }, [pagination]);

  const canGoNext = useMemo(() => {
    return pagination.pageIndex < pageCount - 1;
  }, [pageCount, pagination]);

  if (pageCount < 2) return null;

  return (
    <div className="flex justify-end space-x-6 lg:space-x-8">
      <div className="flex items-center justify-center text-sm font-medium">
        {pagination.pageIndex + 1} / {pageCount}
      </div>
      <div className="flex items-center space-x-2">
        <Button
          variant="outline"
          className="size-8 p-0"
          onClick={() =>
            setPagination({
              ...pagination,
              pageIndex: pagination.pageIndex - 1,
            })
          }
          disabled={!canGoPrevious}
        >
          <ChevronLeftIcon className="size-4" />
        </Button>
        <Button
          variant="outline"
          className="size-8 p-0"
          onClick={() =>
            setPagination({
              ...pagination,
              pageIndex: pagination.pageIndex + 1,
            })
          }
          disabled={!canGoNext}
        >
          <ChevronRightIcon className="size-4" />
        </Button>
      </div>
    </div>
  );
};

import axios from "axios";

import { handleTokens } from "@/services/interceptors/handle-tokens.interceptor";

const usersInstance = axios.create({
  baseURL: `${import.meta.env.VITE_ZONE_API_URL}/users`,
});

handleTokens(usersInstance);

export default usersInstance;

import { TFunction } from "i18next";

import { InterestPeriod } from "../../../common/enums/interest-period.enum";

type Params = {
  interestPeriod: InterestPeriod;
  t: TFunction<"translation", undefined>;
};

export const convertInterestPeriodToTranslation = ({
  t,
  interestPeriod,
}: Params) => {
  switch (interestPeriod) {
    case InterestPeriod.DAILY:
      return t("loans.enums.interestPeriod.daily");
    case InterestPeriod.WEEKLY:
      return t("loans.enums.interestPeriod.weekly");
    case InterestPeriod.MONTHLY:
      return t("loans.enums.interestPeriod.monthly");
    case InterestPeriod.QUARTERLY:
      return t("loans.enums.interestPeriod.quarterly");
    case InterestPeriod.ANNUALLY:
      return t("loans.enums.interestPeriod.annually");
    default:
      return "";
  }
};

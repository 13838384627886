import { ReactNode } from "react";

import Card from "@/components/card/card";
import Column from "@/components/column";
import Row from "@/components/row";

interface Props {
  avatar?: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  actions?: ReactNode[];
}

export const PendingCard = ({ avatar, title, subtitle, actions }: Props) => {
  return (
    <Card className="flex w-full justify-between p-4">
      <Column>
        <Row className="gap-4">
          {avatar}
          <Column>
            {title}
            {subtitle}
          </Column>
        </Row>
      </Column>
      <Column>
        <div className="flex gap-2">
          {actions?.map((action) => (
            <Column className="items-center">{action}</Column>
          ))}
        </div>
      </Column>
    </Card>
  );
};

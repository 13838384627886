import { useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { defineStepper } from "@stepperize/react";
import { Loader2, ArrowLeftIcon } from "lucide-react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { z } from "zod";

import Button from "@/components/button/button";

import { AccountInputs } from "./account-inputs";
import { IdentityInputs } from "./identity-inputs";
import { SecurityInputs } from "./security-inputs";
import { Route } from "../../../../common/enums/route.enum";
import { useCreateAccount } from "../../hooks/use-create-account";
import { useRegisterSchema } from "../../hooks/use-register-schema";

enum STEP {
  IDENTITY = "identity",
  ACCOUNT = "account",
  SECURITY = "security",
}

const { useStepper } = defineStepper(
  {
    index: 0,
    id: STEP.IDENTITY,
  },
  {
    index: 1,
    id: STEP.ACCOUNT,
  },
  {
    index: 2,
    id: STEP.SECURITY,
  }
);

export const RegisterForm = () => {
  const { t } = useTranslation();
  const stepper = useStepper();
  const { step1Schema, step2Schema, step3Schema, fullSchema } =
    useRegisterSchema();

  const currentSchema = useMemo(() => {
    switch (stepper.current.id) {
      case STEP.IDENTITY:
        return step1Schema;
      case STEP.ACCOUNT:
        return step2Schema;
      case STEP.SECURITY:
        return step3Schema;
      default:
        return step1Schema;
    }
  }, [step1Schema, step2Schema, step3Schema, stepper]);

  const form = useForm<z.infer<typeof fullSchema>>({
    resolver: zodResolver(currentSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      address: "",
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    mode: "onSubmit",
  });

  const { handleAccountCreate, isLoading } = useCreateAccount();

  const handlePrev = async () => {
    stepper.prev();
  };

  const handleNext = async () => {
    const isValid = await form.trigger();

    if (!isValid) return;

    if (stepper.isLast) {
      const data = form.getValues();
      handleAccountCreate(data);
    } else {
      stepper.next();
    }
  };

  return (
    <FormProvider {...form}>
      <form className="space-y-4">
        {stepper.switch({
          identity: () => <IdentityInputs />,
          account: () => <AccountInputs />,
          security: () => <SecurityInputs />,
        })}
        <div className="flex items-center justify-between space-x-2">
          {!stepper.isFirst && (
            <Button type="button" variant="outline" onClick={handlePrev}>
              <ArrowLeftIcon className="size-4" />
            </Button>
          )}
          <Button
            type="button"
            onClick={handleNext}
            disabled={isLoading}
            className="grow"
          >
            {isLoading && stepper.isLast && (
              <Loader2 className="mr-2 size-4 animate-spin" />
            )}
            {stepper.isLast
              ? t("profile.actions.register")
              : t("common.actions.continue")}
          </Button>
        </div>
      </form>
      <div className="mt-4 text-sm font-medium">
        <span>{t("profile.sections.register.alreadyHaveAccount")}</span>
        <Link
          to={Route.login}
          className="ml-1 text-blue-600 hover:underline dark:text-blue-500"
        >
          {t("profile.actions.login")}
        </Link>
      </div>
    </FormProvider>
  );
};

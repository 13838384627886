import { LoginPage } from "@/domains/auth/pages/login";
import { NewPasswordPage } from "@/domains/auth/pages/new-password";
import { RegisterPage } from "@/domains/auth/pages/register";
import { ResetPasswordPage } from "@/domains/auth/pages/reset-password";

import { Route } from "../common/enums/route.enum";

export const publicRoutes = [
  {
    path: Route.register,
    element: <RegisterPage />,
  },
  {
    path: Route.login,
    element: <LoginPage />,
  },
  {
    path: Route.resetPassword,
    element: <ResetPasswordPage />,
  },
  {
    path: Route.confirmPassword,
    element: <NewPasswordPage />,
  },
];

import { TFunction } from "i18next";
import { z } from "zod";

type Params = {
  t: TFunction<"translation", undefined>;
};

export const passwordSchema = ({ t }: Params) => {
  return z
    .string({
      required_error: t("common.errors.fieldRequired", {
        field: t("profile.labels.password"),
      }),
    })
    .min(8, {
      message: t("profile.errors.passwordTooShort", { min: 8 }),
    })
    .regex(/[a-z]/, {
      message: t("profile.errors.passwordMissingLowercase"),
    })
    .regex(/[A-Z]/, {
      message: t("profile.errors.passwordMissingUppercase"),
    })
    .regex(/\d/, {
      message: t("profile.errors.passwordMissingNumber"),
    })
    .regex(/[!@#$%^&*(),.?":{}|<>_\-\\[\]/`~;+=]/, {
      message: t("profile.errors.passwordMissingSpecialChar", {
        chars: '[!@#$%^&*(),.?":{}|<>_-\\[]/`~;+=]',
      }),
    });
};

import { TFunction } from "i18next";
import { z } from "zod";

type Params = {
  t: TFunction<"translation", undefined>;
};

export const firstnameSchema = ({ t }: Params) => {
  return z
    .string({
      required_error: t("common.errors.fieldRequired", {
        field: t("identity.labels.firstname"),
      }),
    })
    .min(3, {
      message: t("common.errors.fieldTooShort", {
        field: t("identity.labels.firstname"),
        count: 3,
      }),
    })
    .max(40, {
      message: t("common.errors.fieldTooLong", {
        field: t("identity.labels.firstname"),
        count: 40,
      }),
    });
};

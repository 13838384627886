import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { z } from "zod";

export const useProfileFormSchema = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const schema = z.object({
      username: z
        .string({
          required_error: t("common.errors.fieldRequired", {
            field: t("profile.labels.username"),
          }),
        })
        .min(3, {
          message: t("common.errors.fieldTooShort", {
            field: t("profile.labels.username"),
            min: 3,
          }),
        })
        .max(40, {
          message: t("common.errors.fieldTooLong", {
            field: t("profile.labels.username"),
            max: 40,
          }),
        }),
    });

    return schema;
  }, [t]);
};

import axios from "axios";

import { handleTokens } from "@/services/interceptors/handle-tokens.interceptor";

const connectionsInstance = axios.create({
  baseURL: `${import.meta.env.VITE_ZONE_API_URL}/connections`,
});

handleTokens(connectionsInstance);

export default connectionsInstance;

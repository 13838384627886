import { ArrowRight, CoinsIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { useLoanSchema } from "@/domains/loans/schemas/loan-schema";
import { formatCurrency } from "@/domains/loans/utils/format-currency";

import { IdentityCard } from "./identities/identity-card";
import { TermsSummary } from "./terms-summary";

export const SummaryComponent = () => {
  const { fullSchema } = useLoanSchema();
  const { watch } = useFormContext<z.infer<typeof fullSchema>>();
  const { creditors, debtors, amount, currency } = watch();

  return (
    <div className="space-y-8">
      <TermsSummary />
      <div className="flex flex-col items-center justify-between space-y-8 md:flex-row md:space-x-8 md:space-y-0">
        <div className="w-full space-y-4">
          {creditors.map(({ id, fullname, address }) => (
            <IdentityCard key={id} fullname={fullname} address={address} />
          ))}
        </div>
        <div className="flex w-full flex-col items-center py-4 md:w-auto md:py-0">
          <div className="mb-4 flex items-center md:mb-2">
            <CoinsIcon className="mr-1 size-5 text-green-500" />
            <span className="font-bold md:text-lg">
              {formatCurrency({ amount, currency })}
            </span>
          </div>
          <ArrowRight className="size-8 rotate-90 text-green-500 md:size-16 md:rotate-0" />
        </div>
        <div className="w-full space-y-4">
          {debtors.map(({ id, fullname, address }) => (
            <IdentityCard key={id} fullname={fullname} address={address} />
          ))}
        </div>
      </div>
    </div>
  );
};

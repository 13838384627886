import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { PaginationState } from "@tanstack/react-table";


import { getLoanTransactions } from "@/domains/loans/services/loans.api";
import { loansQueryKeys } from "@/domains/loans/utils/query-keys";
import { DEFAULT_PAGE_INDEX, getServerPagination } from "@/utils/data-browsing";

type Params = {
  loanId: string;
};

export const useGetLoanTransactions = ({ loanId }: Params) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: 5,
  });

  const { page, pageSize } = getServerPagination(pagination);

  const result = useQuery({
    queryKey: loansQueryKeys.transactionDetailPaginated({
      id: loanId,
      page,
      pageSize,
    }),
    queryFn: () => getLoanTransactions({ loanId, page, pageSize }),
  });

  return {
    ...result,
    pagination,
    setPagination,
  };
};

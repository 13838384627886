import * as React from "react";

import { LanguageSelector } from "@/domains/core/components/language-selector";

type Props = {
  title: string;
  description: string;
  children: React.ReactNode;
};

export const AuthLayout = ({ title, description, children }: Props) => {
  return (
    <div className="flex max-h-screen min-h-screen overflow-hidden">
      <div className="hidden bg-blue-600 lg:flex lg:w-1/2">
        <img
          src="/images/landing.webp"
          className="size-full object-cover"
          alt="Landing"
        />
      </div>

      <div className="flex w-full flex-col items-center justify-center gap-8 overflow-y-auto p-4 lg:w-1/2">
        <div className="flex w-full max-w-md flex-col items-center space-y-8">
          <img src="/images/logo.png" alt="Logo" className="h-20" />

          <div className="w-full text-center">
            <h1 className="mb-2 text-4xl font-extrabold text-gray-900">
              {title}
            </h1>
            <p className="text-lg text-gray-600">{description}</p>
          </div>

          <div className="w-full">{children}</div>

          <LanguageSelector />
        </div>
      </div>
    </div>
  );
};

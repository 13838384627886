import { PieChart as RechartsPieChart, Pie, Label } from "recharts";

import { ChartConfig } from "@/components/chart/chart-config.type";
import { ChartContainer } from "@/components/chart/chart-container";
import { ChartTooltip } from "@/components/chart/chart-tooltip";
import { ChartTooltipContent } from "@/components/chart/chart-tooltip-content";

interface PieData {
  label: string;
  value: number;
  color: string;
}

interface Layer {
  data: PieData[];
  innerRadius: string;
  outerRadius: string;
}

interface Props {
  title?: string;
  subtitle?: string;
  layers: Layer[];
  paddingAngle?: number;
  startAngle?: number;
  endAngle?: number;
}

const NAME_KEY = "label";
const DATA_KEY = "value";

export const PieChart = ({
  title = "",
  subtitle = "",
  layers = [],
  paddingAngle = 0,
  startAngle = 0,
  endAngle = 360,
}: Props) => {
  const chartConfig = layers
    .flatMap((layer) => layer.data)
    .reduce((acc, { label, color }) => {
      acc[color] = { label, color: `hsl(var(--${color}))` };
      return acc;
    }, {} as ChartConfig);

  return (
    <ChartContainer config={chartConfig} className="aspect-square">
      <RechartsPieChart>
        <ChartTooltip content={<ChartTooltipContent />} />
        {layers.map(({ data, innerRadius, outerRadius }, index) => (
          <Pie
            key={index}
            nameKey={NAME_KEY}
            dataKey={DATA_KEY}
            data={data.map(({ label, value, color }) => ({
              label,
              value,
              fill: `hsl(var(--${color}))`,
            }))}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            paddingAngle={paddingAngle}
          >
            <Label
              content={({ viewBox }) => {
                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                  const { cx = 0, cy = 0 } = viewBox;
                  const diameter = Math.min(cx * 2, cy * 2);
                  const titleFontSize = diameter * 0.1;
                  const subtitleFontSize = titleFontSize * 0.45;

                  return (
                    <text
                      x={cx}
                      y={cy}
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan
                        x={cx}
                        y={cy}
                        style={{
                          fontSize: `${titleFontSize}px`,
                        }}
                        className="font-bold"
                      >
                        {title}
                      </tspan>
                      <tspan
                        x={cx}
                        y={cy + subtitleFontSize + 15}
                        style={{
                          fontSize: `${subtitleFontSize}px`,
                        }}
                        className="fill-gray-500 font-medium"
                      >
                        {subtitle}
                      </tspan>
                    </text>
                  );
                }
                return null;
              }}
            />
          </Pie>
        ))}
      </RechartsPieChart>
    </ChartContainer>
  );
};

import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { PaginationState } from "@tanstack/react-table";


import { getConnections } from "@/domains/connections/services/connections.api";
import { connectionsQueryKeys } from "@/domains/connections/utils/query-keys";
import {
  getServerPagination,
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
} from "@/utils/data-browsing";

export const useGetConnections = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { page, pageSize } = getServerPagination(pagination);

  const result = useQuery({
    queryKey: connectionsQueryKeys.allPaginated({ page, pageSize }),
    queryFn: () => getConnections({ page, pageSize }),
  });

  return {
    ...result,
    pagination,
    setPagination,
  };
};

import { TFunction } from "i18next";
import { z } from "zod";

type Params = {
  t: TFunction<"translation", undefined>;
};

export const emailSchema = ({ t }: Params) => {
  return z
    .string({
      required_error: t("common.errors.fieldRequired", {
        field: t("profile.labels.email"),
      }),
    })
    .email({
      message: t("common.errors.fieldInvalid", {
        field: t("profile.labels.email"),
      }),
    });
};

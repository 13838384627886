import { FieldValues, Control, Path } from "react-hook-form";

import FormControl from "@/components/form/form-control";
import FormField from "@/components/form/form-field";
import FormItem from "@/components/form/form-item";
import FormMessage from "@/components/form/form-message";
import Label from "@/components/label";
import Textarea from "@/components/textarea";
import cn from "@/utils/cn";

interface Props<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  label?: string;
  placeholder?: string;
  className?: string;
  rows?: number;
}

export const FormTextarea = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  className,
  rows = 3,
}: Props<TFieldValues>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn("w-full", className)}>
          {label && <Label>{label}</Label>}
          <FormControl>
            <Textarea
              placeholder={placeholder}
              {...field}
              className="w-full"
              rows={rows}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

import * as React from "react";

import cn from "@/utils/cn";

export interface ColumnProps extends React.HTMLAttributes<HTMLDivElement> {}

const Column: React.FC<ColumnProps> = ({ className, ...props }) => {
  return <div className={cn("flex flex-col", className)} {...props} />;
};

Column.displayName = "Column";

export default Column;

export const bankDetailsQueryKeys = {
  paymentQrCode: ({
    bankDetailId,
    amount,
    currency,
    dueDate,
    message,
    constantSymbol,
    specificSymbol,
    variableSymbol,
  }: {
    bankDetailId: string;
    amount?: number;
    currency?: string;
    dueDate?: Date;
    message?: string;
    constantSymbol?: string;
    specificSymbol?: string;
    variableSymbol?: string;
  }) =>
    [
      "payment-qr-code",
      bankDetailId,
      amount,
      currency,
      dueDate,
      message,
      constantSymbol,
      specificSymbol,
      variableSymbol,
    ] as const,
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

import { Loan } from "@/domains/loans/interfaces/loan.interface";
import { useLoanSchema } from "@/domains/loans/schemas/loan-schema";
import { createLoan } from "@/domains/loans/services/loans.api";

import { Route } from "../../../common/enums/route.enum";
import { useErrorTranslation } from "../../../common/hooks/use-error-translation";

type Params = {
  onSuccess?: () => void;
};

export const useCreateLoan = ({ onSuccess }: Params) => {
  const { t } = useTranslation();
  const { translateError } = useErrorTranslation();
  const { fullSchema } = useLoanSchema();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: async (data: z.infer<typeof fullSchema>) => {
      const startDate = format(new Date(data.startDate), "yyyy-MM-dd");
      const dueDate = format(new Date(data.dueDate), "yyyy-MM-dd");

      if (!startDate || !dueDate) {
        throw new Error("Invalid date received");
      }

      const response = await createLoan({
        name: data.name,
        description: data.description,
        amount: data.amount,
        currency: data.currency,
        interestRate: data.interestRate,
        interestPeriod: data.interestPeriod,
        startDate,
        dueDate,
        creditorIds: data.creditors.map(({ id }) => id),
        debtorIds: data.debtors.map(({ id }) => id),
      });

      return { loan: response.data };
    },
    onSuccess: async ({ loan }: { loan: Loan }) => {
      toast.success(t("common.toasts.general.success.title"));
      await queryClient.invalidateQueries();
      onSuccess?.();
      navigate(`${Route.loans}/${loan.id}`);
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      toast.error(t("common.toasts.general.failure.title"), {
        description: translateError(error),
      });
    },
  });

  return {
    handleCreateLoan: mutate,
    isLoading: isPending,
  };
};

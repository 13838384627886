import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { signout } from "@/domains/auth/services/auth.api";

import { useAuthStore } from "./use-auth-store";
import { useErrorTranslation } from "../../../common/hooks/use-error-translation";

export const useSignout = () => {
  const { t } = useTranslation();

  const { translateError } = useErrorTranslation();

  const { refreshToken, setRefreshToken, setAccessToken } = useAuthStore();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      if (!refreshToken) throw new Error("Refresh token unavailable");

      await signout({ token: refreshToken });
    },
    onSuccess: () => {
      setRefreshToken(null);
      setAccessToken(null);

      setTimeout(() => {}, 1500);

      navigate(0);

      toast.success(t("common.toasts.general.success.title"));
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      toast.error(t("common.toasts.general.failure.title"), {
        description: translateError(error),
      });
    },
  });

  return {
    handleSignout: mutate,
    isLoading: isPending,
  };
};

import { getLocale } from "@/domains/core/hooks/use-settings-store";

import { Currency } from "../../../common/enums/currency.enum";

export const formatCurrency = ({
  amount,
  currency,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
}: {
  amount: number;
  currency: Currency;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}): string => {
  const locale = getLocale();

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return formatter.format(amount);
};

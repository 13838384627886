import type { AxiosInstance } from "axios";

import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "@/domains/auth/hooks/use-auth-store";
import { refresh } from "@/domains/auth/services/auth.api";

export const handleTokens = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async (config) => {
      const accessToken = getAccessToken();
      config.headers.setAuthorization(`Bearer ${accessToken}`);
      return Promise.resolve(config);
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const refreshToken = getRefreshToken();

      const originalRequest = error?.config;

      // If not Unauthorized error, rethrow it
      if (!(error?.response?.status === 401 && !originalRequest?._retry)) {
        return Promise.reject(error);
      }

      try {
        if (!refreshToken) throw new Error("Refresh token unavailable");

        // Refresh the access token
        const { accessToken } = await refresh({ token: refreshToken });

        // Update things with the new access token
        setAccessToken(accessToken);
        error.config.headers["Authorization"] = `Bearer ${accessToken}`;

        // Retry the original request
        originalRequest._retry = true;
        return instance(originalRequest);
      } catch (refreshError) {
        // Nothing worked, remove all user data and redirect
        setRefreshToken(null);
        setAccessToken(null);

        throw refreshError;
      }
    }
  );
};

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useLoanSchema } from "@/domains/loans/schemas/loan-schema";

import { IdentitySelector } from "./identities/identity-selector";
import { StepHeader } from "./step-header";

export const DebtorsStep = () => {
  const { t } = useTranslation();
  const { step2Schema } = useLoanSchema();

  const { watch } = useFormContext<z.infer<typeof step2Schema>>();

  const debtors = watch("debtors");

  return (
    <div className="space-y-8">
      <StepHeader
        title={t("loans.sections.create.debtors.title")}
        description={t("loans.sections.create.debtors.description")}
      />

      <IdentitySelector name="debtors" selectedParticipants={debtors} />
    </div>
  );
};

import { X } from "lucide-react";
import { useTranslation } from "react-i18next";

import Avatar from "@/components/avatar/avatar";
import AvatarFallback from "@/components/avatar/avatar-fallback";
import Button from "@/components/button/button";
import Card from "@/components/card/card";
import CardContent from "@/components/card/card-content";
import Column from "@/components/column";
import { useGetRequestedRequests } from "@/domains/connections/hooks/use-get-requested-requests";
import { Pagination } from "@/domains/core/components/pagination";

import { PendingCard } from "./pending-card";

export const PendingRequests = () => {
  const { t } = useTranslation();

  const { data, pagination, setPagination } = useGetRequestedRequests();

  return (
    <Column className="w-full gap-2">
      {data?.data.length === 0 && (
        <Card className="p-4">
          <CardContent>
            {t("connections.sections.pendingRequests.noRequests")}
          </CardContent>
        </Card>
      )}
      {data?.data.map((user) => (
        <PendingCard
          avatar={
            <Avatar className="size-12">
              <AvatarFallback value={user.username} />
            </Avatar>
          }
          title={
            <div className="text-lg font-medium">
              {t("connections.sections.pendingRequests.cardTitle", {
                username: user.username,
              })}
            </div>
          }
          subtitle={
            <div className="text-sm text-muted-foreground">{user.email}</div>
          }
          actions={[
            <Button size="sm" variant="destructive" disabled={true}>
              <X className="size-4 sm:mr-2" />
              <span className="hidden sm:inline">
                {t("connections.actions.cancel")}
              </span>
            </Button>,
          ]}
        />
      ))}
      <Pagination
        pagination={pagination}
        setPagination={setPagination}
        total={data?.total}
      />
    </Column>
  );
};

import { QueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";

const STALE_TIME = 0;
const MAX_RETRIES = 5;
const RETRY_HTTP_STATUS_CODES: number[] = [408, 500, 502, 503, 504];

export const createQueryClient = (): QueryClient => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: STALE_TIME,
        retry: (failureCount: number, error: unknown) => {
          console.error("API error", error);

          if (isAxiosError(error)) {
            const status = error.response?.status;
            console.debug(
              `Retry attempt ${failureCount} for status code: ${status}`
            );

            if (
              status &&
              RETRY_HTTP_STATUS_CODES.includes(status) &&
              failureCount < MAX_RETRIES
            ) {
              return true;
            }
          }

          return false;
        },
        retryDelay: (retryAttempt: number) =>
          Math.min(1000 * 2 ** retryAttempt, 30000),
      },
    },
  });
};

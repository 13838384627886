import { useQuery } from "@tanstack/react-query";

import { getTransactionsSummary } from "@/domains/loans/services/loans.api";
import { loansQueryKeys } from "@/domains/loans/utils/query-keys";

type Params = {
  loanId: string;
};

export const useGetTransactionsSummary = ({ loanId }: Params) =>
  useQuery({
    queryKey: loansQueryKeys.transactionsSummary({ id: loanId }),
    queryFn: () => getTransactionsSummary({ loanId }),
  });

import { PlusCircleIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@/components/button/button";
import Separator from "@/components/separator";
import { LanguageSelector } from "@/domains/core/components/language-selector";
import { Profile } from "@/domains/users/components/profile";

import { SiteLinks } from "./links";
import { Route } from "../../../../common/enums/route.enum";

export const NavigationContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex size-full flex-1 flex-col">
      <div className="p-4">
        <Button
          variant="default"
          className="w-full rounded-lg font-bold text-white shadow-lg transition duration-200 hover:scale-105"
          onClick={() => navigate(`${Route.loans}/create`)}
        >
          <PlusCircleIcon className="mr-2 size-5" />
          {t("loans.actions.createLoan")}
        </Button>
      </div>
      <Separator />
      <div className="flex-1 p-4">
        <SiteLinks />
      </div>
      <div className="p-4">
        <Profile />
      </div>
      <Separator />
      <div className="p-4">
        <LanguageSelector />
      </div>
    </div>
  );
};

import { useState } from "react";

import { MapPinIcon, PenSquare, PlusCircleIcon, UserIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

import Button from "@/components/button/button";
import Card from "@/components/card/card";
import CardContent from "@/components/card/card-content";
import CardHeader from "@/components/card/card-header";
import CardTitle from "@/components/card/card-title";
import Dialog from "@/components/dialog/dialog";
import DialogContent from "@/components/dialog/dialog-content";
import DialogHeader from "@/components/dialog/dialog-header";
import DialogTitle from "@/components/dialog/dialog-title";
import { IdentityForm } from "@/domains/identities/components/my-identity/identity-form";
import { useGetMyIdentity } from "@/domains/identities/hooks/use-get-my-identity";
import { Identity } from "@/domains/identities/interfaces/identity.interface";

export const IdentitySection = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data: identity } = useGetMyIdentity();

  return (
    <Card className="overflow-hidden">
      <CardHeader className="flex flex-row items-center justify-between bg-gray-100 p-2">
        <CardTitle className="flex items-center text-xl font-semibold">
          <UserIcon className="size-5" />
          <span className="ml-2">{t("profile.sections.identity.title")}</span>
        </CardTitle>
        {identity && (
          <Button variant="ghost" size="sm" onClick={() => setIsOpen(true)}>
            <PenSquare className="size-4" />
          </Button>
        )}
      </CardHeader>
      <CardContent className="p-4">
        {identity ? (
          <IdentityContent identity={identity} />
        ) : (
          <EmptyContent action={() => setIsOpen(true)} />
        )}
      </CardContent>
      {isOpen && (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{t("profile.sections.identity.title")}</DialogTitle>
            </DialogHeader>
            <div className="mx-auto w-full max-w-xl">
              <IdentityForm onSuccess={() => setIsOpen(false)} />
            </div>
          </DialogContent>
        </Dialog>
      )}
    </Card>
  );
};

const IdentityContent = ({ identity }: { identity: Identity }) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-2">
      <div className="flex items-center space-x-2">
        <UserIcon className="size-5 shrink-0 text-muted-foreground" />
        <p className="truncate text-sm font-medium">
          {identity.firstName} {identity.lastName}
        </p>
      </div>
      <div className="flex items-center space-x-2">
        <MapPinIcon className="size-5 shrink-0 text-muted-foreground" />
        <p className="truncate text-sm font-medium">
          {identity.address ?? t("identity.labels.addressEmpty")}
        </p>
      </div>
    </div>
  );
};

const EmptyContent = ({ action }: { action: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className="py-4 text-center">
      <p className="mb-4 text-sm text-muted-foreground">
        {t("profile.sections.identity.empty")}
      </p>
      <Button onClick={action} variant="outline" className="w-full sm:w-auto">
        <PlusCircleIcon className="mr-2 size-4" />
        {t("profile.actions.addIdentity")}
      </Button>
    </div>
  );
};

import { useMemo } from "react";

import { UsersRoundIcon, LayoutDashboard } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import cn from "@/utils/cn";

import { Route } from "../../../../common/enums/route.enum";

type Props = {
  className?: string;
};

export const SiteLinks = ({ className }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const links = useMemo(
    () => [
      {
        icon: LayoutDashboard,
        title: t("common.navigation.loans"),
        to: Route.loans,
      },
      {
        icon: UsersRoundIcon,
        title: t("common.navigation.connections"),
        to: Route.connections,
      },
    ],
    [t]
  );

  return (
    <div className={cn("flex flex-col gap-3", className)}>
      {links.map((link) => {
        const isActive = location.pathname.startsWith(link.to);

        return (
          <Link key={link.title} to={link.to}>
            <div
              className={cn("flex items-center rounded-lg p-2", {
                "bg-gray-300": isActive,
              })}
            >
              <link.icon className="mr-2" /> <span>{link.title}</span>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

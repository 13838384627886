import { TFunction } from "i18next";

import { TransactionType } from "../../../common/enums/transaction-type";

type Params = {
  transactionType: TransactionType;
  t: TFunction<"translation", undefined>;
};

export const convertTransactionTypeToTranslation = ({
  t,
  transactionType,
}: Params) => {
  switch (transactionType) {
    case TransactionType.PAYMENT:
      return t("payments.enums.transactionType.installment");
    case TransactionType.INTEREST:
      return t("payments.enums.transactionType.interest");
    default:
      return "";
  }
};

import { LoanCreatePage } from "@/domains/loans/pages/loan-create";
import { LoanDetailPage } from "@/domains/loans/pages/loan-detail";
import { LoansPage } from "@/domains/loans/pages/loans";

import { Route } from "../common/enums/route.enum";

export const loanRoutes = [
  {
    path: `${Route.loans}/create`,
    element: <LoanCreatePage />,
  },
  {
    path: `${Route.loans}/:id`,
    element: <LoanDetailPage />,
  },
  {
    path: Route.loans,
    element: <LoansPage />,
  },
];

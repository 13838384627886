import Carousel from "@/components/carousel/carousel";
import CarouselContent from "@/components/carousel/carousel-content";
import CarouselItem from "@/components/carousel/carousel-item";
import CarouselNext from "@/components/carousel/carousel-next";
import CarouselPrevious from "@/components/carousel/carousel-previous";
import Column from "@/components/column";
import { Identity } from "@/domains/identities/interfaces/identity.interface";
import { getIdentityLabel } from "@/domains/identities/utils/get-identity-label";

import { BankDetailQrCode } from "./bank-detail-qr-code";

interface Props {
  amount: number;
  creditors: Identity[];
}

export const BankDetailsCarousel = ({ amount, creditors }: Props) => {
  return (
    <Carousel>
      <CarouselContent>
        {creditors.map(
          (creditor) =>
            creditor.bankDetail && (
              <CarouselItem key={creditor.bankDetail.id}>
                <Column className="flex items-center">
                  <div className="size-32">
                    <BankDetailQrCode
                      bankDetailId={creditor.bankDetail.id}
                      amount={amount}
                    />
                  </div>
                  <p className="font-bold">{getIdentityLabel(creditor)}</p>
                  <p className="text-xs">{creditor.bankDetail.iban}</p>
                </Column>
              </CarouselItem>
            )
        )}
      </CarouselContent>
      {creditors.length > 1 && (
        <>
          <CarouselPrevious />
          <CarouselNext />
        </>
      )}
    </Carousel>
  );
};

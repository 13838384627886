import axios from "axios";

import { handleTokens } from "@/services/interceptors/handle-tokens.interceptor";

const identitiesInstance = axios.create({
  baseURL: `${import.meta.env.VITE_ZONE_API_URL}/identities`,
});

handleTokens(identitiesInstance);

export default identitiesInstance;

import { useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import Button from "@/components/button/button";
import { FormInput } from "@/domains/core/components/form/form-input";
import { useGetMe } from "@/domains/users/hooks/use-get-me";
import { useProfileFormSchema } from "@/domains/users/hooks/use-profile-form-schema";
import { useProfileUpdate } from "@/domains/users/hooks/use-update-profile";


interface Props {
  onSuccess?: () => void;
}

export const ProfileForm = ({ onSuccess }: Props) => {
  const { t } = useTranslation();

  const { data: profile } = useGetMe();
  const { handleProfileUpdate, isLoading } = useProfileUpdate({ onSuccess });

  const schema = useProfileFormSchema();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      username: profile?.username,
    },
  });

  useEffect(() => {
    if (!profile) return;

    form.reset({ username: profile.username });
  }, [form, profile]);

  const onSubmit = ({ username }: z.infer<typeof schema>) => {
    handleProfileUpdate({ username });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormInput
          control={form.control}
          name={"username"}
          type="text"
          label={t("profile.labels.username")}
        />
        <Button type="submit" disabled={isLoading}>
          {isLoading && <Loader2 className="mr-2 size-4 animate-spin" />}
          {t("common.actions.saveChanges")}
        </Button>
      </form>
    </FormProvider>
  );
};

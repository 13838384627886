import { useTranslation } from "react-i18next";

import { LoginForm } from "@/domains/auth/components/login-form";
import { AuthLayout } from "@/domains/core/components/layouts/auth-layout";

export const LoginPage = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout
      title={t("profile.sections.login.title")}
      description={t("profile.sections.login.description")}
    >
      <LoginForm />
    </AuthLayout>
  );
};

import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { z } from "zod";

import { addressSchema } from "../../../common/schemas/address";
import { emailSchema } from "../../../common/schemas/email";
import { firstnameSchema } from "../../../common/schemas/firstname";
import { lastnameSchema } from "../../../common/schemas/lastname";
import { passwordSchema } from "../../../common/schemas/password";
import { usernameSchema } from "../../../common/schemas/username";

export const useRegisterSchema = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    // Step 1: Identity
    const step1Schema = z.object({
      firstName: firstnameSchema({ t }),
      lastName: lastnameSchema({ t }),
      address: addressSchema({ t }),
    });

    // Step 2: Username and Email
    const step2Schema = z.intersection(
      step1Schema,
      z.object({
        username: usernameSchema({ t }),
        email: emailSchema({ t }),
      })
    );

    // Step 3: Password and Confirmation
    const step3Schema = z.intersection(
      step2Schema,
      z
        .object({
          password: passwordSchema({ t }),
          confirmPassword: z.string({
            required_error: t("common.errors.fieldRequired", {
              field: t("profile.labels.confirmPassword"),
            }),
          }),
        })
        .refine((data) => data.password === data.confirmPassword, {
          message: t("profile.errors.passwordsMustMatch"),
          path: ["confirmPassword"],
        })
    );

    // Full schema (all steps combined)
    const fullSchema = step3Schema;

    return {
      step1Schema,
      step2Schema,
      step3Schema,
      fullSchema,
    };
  }, [t]);
};

import * as React from "react";

import SkeletonRow from "./skeleton-row";

interface Props {
  rowsCount: number;
}

const Skeleton: React.FC<Props> = ({ rowsCount }) => {
  return (
    <div className="space-y-2">
      {Array.from({ length: rowsCount }).map((_, index) => (
        <SkeletonRow key={index} className="h-4 w-full" />
      ))}
    </div>
  );
};

export default Skeleton;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

import { createMyIdentity } from "@/domains/identities/services/identities.api";

import { useErrorTranslation } from "../../../common/hooks/use-error-translation";

type Params = {
  onSuccess?: () => void;
};

export const useCreateIdentity = ({ onSuccess }: Params) => {
  const { t } = useTranslation();
  const { translateError } = useErrorTranslation();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: async ({
      firstName,
      lastName,
      address,
    }: {
      firstName: string;
      lastName: string;
      address?: string;
    }) => {
      return createMyIdentity({ firstName, lastName, address });
    },
    onSuccess: async () => {
      toast.success(t("common.toasts.general.success.title"));
      await queryClient.invalidateQueries();
      onSuccess?.();
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      toast.error(t("common.toasts.general.failure.title"), {
        description: translateError(error),
      });
    },
  });

  return {
    handleCreateIdentity: mutate,
    isLoading: isPending,
  };
};

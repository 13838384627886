import { useEffect, useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import Button from "@/components/button/button";
import { useBankDetailSchema } from "@/domains/bank-details/hooks/use-bank-detail-schema";
import { useCreateBankDetail } from "@/domains/bank-details/hooks/use-create-bank-detail";
import { useUpdateBankDetail } from "@/domains/bank-details/hooks/use-update-bank-detail";
import { FormInput } from "@/domains/core/components/form/form-input";
import { Identity } from "@/domains/identities/interfaces/identity.interface";

interface Props {
  identity?: Identity;
  onSuccess?: () => void;
}

export const BankDetailForm = ({ identity, onSuccess }: Props) => {
  const { t } = useTranslation();

  const { handleCreateBankDetail, isLoading: isCreatingBankDetail } =
    useCreateBankDetail({
      onSuccess,
    });
  const { handleUpdateBankDetail, isLoading: isUpdatingBankDetail } =
    useUpdateBankDetail({
      onSuccess,
    });

  const schema = useBankDetailSchema();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const bankDetail = useMemo(() => identity?.bankDetail, [identity]);

  useEffect(() => {
    if (!bankDetail) return;

    form.reset({ iban: bankDetail.iban });
  }, [form, bankDetail]);

  const onSubmit = ({ iban }: z.infer<typeof schema>) => {
    if (bankDetail) {
      handleUpdateBankDetail({ bankDetailId: bankDetail.id, iban });
    } else {
      handleCreateBankDetail({ iban, identityId: identity?.id });
    }
  };

  const isLoading = useMemo(
    () => isCreatingBankDetail || isUpdatingBankDetail,
    [isCreatingBankDetail, isUpdatingBankDetail]
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormInput
          control={form.control}
          name={"iban"}
          type="text"
          label={t("bankDetails.labels.iban")}
        />
        <Button type="submit" disabled={isLoading}>
          {isLoading && <Loader2 className="mr-2 size-4 animate-spin" />}
          {t("common.actions.saveChanges")}
        </Button>
      </form>
    </FormProvider>
  );
};

import { FieldValues, Control, Path } from "react-hook-form";

import FormControl from "@/components/form/form-control";
import FormField from "@/components/form/form-field";
import FormItem from "@/components/form/form-item";
import FormMessage from "@/components/form/form-message";
import Label from "@/components/label";
import Select from "@/components/select/select";
import SelectContent from "@/components/select/select-content";
import SelectItem from "@/components/select/select-item";
import SelectTrigger from "@/components/select/select-trigger";
import SelectValue from "@/components/select/select-value";

interface Option {
  value: string;
  name: string;
}

interface SelectFieldProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  options: Option[];
  label?: string;
  placeholder?: string;
  className?: string;
}

export const FormSelect = <TFieldValues extends FieldValues>({
  control,
  name,
  options,
  label,
  placeholder,
  className,
}: SelectFieldProps<TFieldValues>) => (
  <FormField
    control={control}
    name={name}
    render={({ field }) => (
      <FormItem className={className}>
        {label && <Label>{label}</Label>}
        <Select
          value={field.value}
          onValueChange={(value) => field.onChange(value)}
        >
          <FormControl>
            <SelectTrigger>
              <SelectValue placeholder={placeholder} />
            </SelectTrigger>
          </FormControl>
          <SelectContent>
            {options.map(({ value, name }) => (
              <SelectItem value={value} key={`${name}_${value}`}>
                {name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <FormMessage />
      </FormItem>
    )}
  />
);

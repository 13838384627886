import * as React from "react";

import { Menu } from "lucide-react";
import { Link } from "react-router-dom";

import LogoWithText from "@/assets/logo-with-text.svg";
import Button from "@/components/button/button";
import Sheet from "@/components/sheet/sheet";
import SheetContent from "@/components/sheet/sheet-content";
import SheetTrigger from "@/components/sheet/sheet-trigger";

import { NavigationContent } from "./navigation-content";

export const MobileNavigation = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="flex w-full justify-between px-4 py-2">
      <Link to="/">
        <LogoWithText className="h-10" />
      </Link>
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetTrigger asChild>
          <Button variant="ghost">
            <Menu />
          </Button>
        </SheetTrigger>
        <SheetContent>
          <NavigationContent />
        </SheetContent>
      </Sheet>
    </div>
  );
};

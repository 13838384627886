import { useTranslation } from "react-i18next";
import { z } from "zod";

export const useResetPasswordSchema = () => {
  const { t } = useTranslation();

  return z.object({
    identifier: z
      .string({
        required_error: t("common.errors.fieldRequired", {
          field: t("profile.labels.usernameOrEmail"),
        }),
      })
      .min(1, {
        message: t("common.errors.fieldTooShort", {
          field: t("profile.labels.usernameOrEmail"),
          count: 1,
        }),
      }),
  });
};

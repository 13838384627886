import { useTranslation } from "react-i18next";
import { z } from "zod";

export const useLoginSchema = () => {
  const { t } = useTranslation();

  return z.object({
    identifier: z
      .string({
        required_error: t("common.errors.fieldRequired", {
          field: t("profile.labels.usernameOrEmail"),
        }),
      })
      .min(1, {
        message: t("common.errors.fieldRequired", {
          field: t("profile.labels.usernameOrEmail"),
        }),
      }),
    password: z
      .string({
        required_error: t("common.errors.fieldRequired", {
          field: t("profile.labels.password"),
        }),
      })
      .min(1, {
        message: t("common.errors.fieldRequired", {
          field: t("profile.labels.password"),
        }),
      }),
  });
};

import {
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInQuarters,
  differenceInYears,
} from "date-fns";

import { InterestPeriod } from "../../../common/enums/interest-period.enum";

type Params = {
  amount: number;
  interestRate: number;
  interestPeriod: InterestPeriod;
  startDate?: Date;
  dueDate?: Date;
};

export const getInterestAmount = ({
  amount,
  interestRate,
  interestPeriod,
  startDate,
  dueDate,
}: Params) => {
  if (!startDate || !dueDate) {
    return 0;
  }

  switch (interestPeriod) {
    case InterestPeriod.DAILY:
      return (
        amount * (interestRate / 100) * differenceInDays(dueDate, startDate)
      );
    case InterestPeriod.WEEKLY:
      return (
        amount * (interestRate / 100) * differenceInWeeks(dueDate, startDate)
      );
    case InterestPeriod.MONTHLY:
      return (
        amount * (interestRate / 100) * differenceInMonths(dueDate, startDate)
      );
    case InterestPeriod.QUARTERLY:
      return (
        amount * (interestRate / 100) * differenceInQuarters(dueDate, startDate)
      );
    case InterestPeriod.ANNUALLY:
      return (
        amount * (interestRate / 100) * differenceInYears(dueDate, startDate)
      );
  }
};

import { useState } from "react";

import { AtSignIcon, MailIcon, PenSquare, User } from "lucide-react";
import { useTranslation } from "react-i18next";

import Button from "@/components/button/button";
import Card from "@/components/card/card";
import CardContent from "@/components/card/card-content";
import CardHeader from "@/components/card/card-header";
import CardTitle from "@/components/card/card-title";
import Dialog from "@/components/dialog/dialog";
import DialogContent from "@/components/dialog/dialog-content";
import DialogHeader from "@/components/dialog/dialog-header";
import DialogTitle from "@/components/dialog/dialog-title";
import { useGetMe } from "@/domains/users/hooks/use-get-me";

import { ProfileForm } from "./profile-form";

export const ProfileSection = () => {
  const { t } = useTranslation();
  const { data: profile } = useGetMe();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Card className="overflow-hidden">
      <CardHeader className="flex flex-row items-center justify-between bg-gray-100 p-2">
        <CardTitle className="flex items-center text-xl font-semibold">
          <User className="size-5 text-primary" />
          <span className="ml-2">{t("profile.sections.profile.title")}</span>
        </CardTitle>
        <Button variant="ghost" size="sm" onClick={() => setIsOpen(true)}>
          <PenSquare className="size-4" />
        </Button>
      </CardHeader>
      <CardContent className="p-4">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <AtSignIcon className="size-5 text-muted-foreground" />
            <p className="text-sm font-medium">{profile?.username}</p>
          </div>
          <div className="flex items-center space-x-2">
            <MailIcon className="size-5 text-muted-foreground" />
            <p className="text-sm text-muted-foreground">{profile?.email}</p>
          </div>
        </div>
      </CardContent>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("profile.sections.profile.title")}</DialogTitle>
          </DialogHeader>
          <div className="mx-auto w-full max-w-xl">
            <ProfileForm onSuccess={() => setIsOpen(false)} />
          </div>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

import { create } from "zustand";
import { persist } from "zustand/middleware";

import { SettingsStore } from "@/domains/core/interfaces/settings-store.interface";

// STORE
export const useSettingsStore = create<SettingsStore>()(
  persist(
    (set) => ({
      locale: "cs",
      setLocale: (locale) => set(() => ({ locale })),
    }),
    {
      name: "settings",
    }
  )
);

// SELECTORS
export const getLocale = () => useSettingsStore.getState().locale;
export const setLocale = (language: string) =>
  useSettingsStore.getState().setLocale(language);

import { useQuery } from "@tanstack/react-query";


import { searchUsers } from "@/domains/users/services/users.api";
import { usersQueryKeys } from "@/domains/users/utils/query-keys";
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "@/utils/data-browsing";

type Params = {
  query?: string;
  page?: number;
  pageSize?: number;
};

export const useSearchUsers = ({
  query = "",
  page = DEFAULT_PAGE,
  pageSize = DEFAULT_PAGE_SIZE,
}: Params) =>
  useQuery({
    queryKey: usersQueryKeys.allPaginated({ query, page, pageSize }),
    queryFn: () => searchUsers({ query, page, pageSize }),
  });

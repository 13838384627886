import { CheckCircle, XCircle } from "lucide-react";
import { useTranslation } from "react-i18next";

import Button from "@/components/button/button";
import { useGetMyIdentity } from "@/domains/identities/hooks/use-get-my-identity";
import { useCancelTransaction } from "@/domains/loans/hooks/use-cancel-transaction";
import { useConfirmTransaction } from "@/domains/loans/hooks/use-confirm-transaction";
import { LoanTransaction } from "@/domains/loans/interfaces/loan-transaction";
import { Loan } from "@/domains/loans/interfaces/loan.interface";

import { TransactionType } from "../../../../../common/enums/transaction-type";

type Props = {
  loan: Loan;
  transaction: LoanTransaction;
};

export const TransactionCardFooter = ({ loan, transaction }: Props) => {
  const { t } = useTranslation();

  const { data: identity } = useGetMyIdentity();

  const { handleConfirmTransaction } = useConfirmTransaction({});
  const { handleCancelTransaction } = useCancelTransaction({});

  const isBetweenUsers = [TransactionType.PAYMENT].includes(transaction.type);
  const hasConfirmation = !!transaction.confirmation?.id;

  if (hasConfirmation || !isBetweenUsers) {
    return null;
  }

  const isSender = transaction.sender?.id === identity?.id;
  const isReceiver = transaction.receiver?.id === identity?.id;

  return (
    <div className="ml-auto flex space-x-2">
      <Button
        className="bg-green-500 p-5 text-white hover:bg-green-600"
        onClick={() =>
          handleConfirmTransaction({
            loanId: loan.id,
            transactionId: transaction.id,
          })
        }
        disabled={isSender}
      >
        <CheckCircle className="mr-2 size-5" />
        {t("payments.actions.acceptPayment")}
      </Button>
      <Button
        variant="outline"
        className="border-red-500 p-5 text-red-500 hover:bg-red-50"
        onClick={() =>
          handleCancelTransaction({
            loanId: loan.id,
            transactionId: transaction.id,
          })
        }
        disabled={isReceiver}
      >
        <XCircle className="mr-2 size-5" />
        {t("payments.actions.declinePayment")}
      </Button>
    </div>
  );
};

import { useTranslation } from "react-i18next";

import { PageHeader } from "@/domains/core/components/pages/page-header";
import { Title } from "@/domains/core/components/typography/title";

export const ConnectionsHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      title={<Title>{t("connections.sections.header.title")}</Title>}
    ></PageHeader>
  );
};

import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { AuthResponse } from "@/domains/auth/interfaces/auth-response.interface";
import { register } from "@/domains/auth/services/auth.api";

import { useAuthStore } from "./use-auth-store";
import { useErrorTranslation } from "../../../common/hooks/use-error-translation";

type Params = {
  onSuccess?: () => void;
};

export const useRegister = ({ onSuccess }: Params) => {
  const { t } = useTranslation();
  const { translateError } = useErrorTranslation();
  const { setRefreshToken, setAccessToken } = useAuthStore();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: async ({
      username,
      email,
      password,
    }: {
      username: string;
      email: string;
      password: string;
    }) => {
      return register({ username, email, password });
    },
    onSuccess: (response: AuthResponse) => {
      setRefreshToken(response.refreshToken);
      setAccessToken(response.accessToken);

      toast.success(t("common.toasts.general.success.title"));

      onSuccess?.();

      navigate(0);
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      toast.error(t("common.toasts.general.failure.title"), {
        description: translateError(error),
      });
    },
  });

  return {
    handleRegister: mutate,
    isLoading: isPending,
  };
};

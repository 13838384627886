import * as React from "react";

import cn from "@/utils/cn";

export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {}

const Row: React.FC<RowProps> = ({ className, ...props }) => {
  return (
    <div className={cn("flex flex-row", className)} {...props} />
  );
};

Row.displayName = "Row";

export default Row;

import { useState, useMemo } from "react";

import {
  CreditCardIcon,
  PenSquare,
  PlusCircleIcon,
  UserIcon,
} from "lucide-react";
import { Trans, useTranslation } from "react-i18next";

import Button from "@/components/button/button";
import Card from "@/components/card/card";
import CardContent from "@/components/card/card-content";
import CardHeader from "@/components/card/card-header";
import CardTitle from "@/components/card/card-title";
import Dialog from "@/components/dialog/dialog";
import DialogContent from "@/components/dialog/dialog-content";
import DialogHeader from "@/components/dialog/dialog-header";
import DialogTitle from "@/components/dialog/dialog-title";
import { BankDetailForm } from "@/domains/bank-details/components/bank-detail-form";
import { BankDetail } from "@/domains/bank-details/interfaces/bank-detail.interface";
import { useGetMyIdentity } from "@/domains/identities/hooks/use-get-my-identity";

import DialogDescription from "../../../common/components/dialog/dialog-description";

export const BankDetailSection = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data: identity } = useGetMyIdentity();

  const bankDetail = useMemo(() => identity?.bankDetail, [identity]);

  return (
    <Card className="overflow-hidden">
      <CardHeader className="flex flex-row items-center justify-between bg-gray-100 p-2">
        <CardTitle className="flex items-center text-xl font-semibold">
          <UserIcon className="size-5" />
          <span className="ml-2">{t("profile.sections.bankDetail.title")}</span>
        </CardTitle>
        {bankDetail && (
          <Button variant="ghost" size="sm" onClick={() => setIsOpen(true)}>
            <PenSquare className="size-4" />
          </Button>
        )}
      </CardHeader>
      <CardContent className="p-4">
        {bankDetail ? (
          <BankDetailContent bankDetail={bankDetail} />
        ) : (
          <EmptyContent action={() => setIsOpen(true)} />
        )}
      </CardContent>
      {isOpen && (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                {t("profile.sections.bankDetail.title")}
              </DialogTitle>
              <DialogDescription>
                <Trans
                  i18nKey="profile.sections.bankDetail.description"
                  components={{
                    customLink: (
                      <a
                        className="text-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              </DialogDescription>
            </DialogHeader>
            <div className="mx-auto w-full max-w-xl">
              <BankDetailForm
                identity={identity}
                onSuccess={() => setIsOpen(false)}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}
    </Card>
  );
};

const BankDetailContent = ({ bankDetail }: { bankDetail: BankDetail }) => (
  <div className="space-y-2">
    <div className="flex items-center space-x-2">
      <CreditCardIcon className="size-5 text-muted-foreground" />
      <p className="text-sm font-medium">{bankDetail.iban}</p>
    </div>
  </div>
);

const EmptyContent = ({ action }: { action: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className="py-4 text-center">
      <p className="mb-4 text-sm text-muted-foreground">
        {t("profile.sections.bankDetail.empty")}
      </p>
      <Button onClick={action} variant="outline" className="w-full sm:w-auto">
        <PlusCircleIcon className="mr-2 size-4" />
        {t("profile.actions.addBankDetail")}
      </Button>
    </div>
  );
};

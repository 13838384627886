import Row from "@/components/row";
import Skeleton from "@/components/skeleton/skeleton";
import { PageHeader } from "@/domains/core/components/pages/page-header";
import { Subtitle } from "@/domains/core/components/typography/subtitle";
import { Title } from "@/domains/core/components/typography/title";
import { LoanBadges } from "@/domains/loans/components/loan-badges";
import { useGetLoan } from "@/domains/loans/hooks/use-get-loan";

type Props = {
  loanId: string;
};

export const LoanDetailHeader = ({ loanId }: Props) => {
  const { data: loan } = useGetLoan({ loanId });

  if (!loan) {
    return <Skeleton rowsCount={2} />;
  }

  return (
    <PageHeader
      title={
        <Row className="items-center gap-2">
          <Title>{loan.name}</Title>
          <Row className="h-6 gap-1">
            <LoanBadges loan={loan} />
          </Row>
        </Row>
      }
      subtitle={<Subtitle>{loan.description}</Subtitle>}
    ></PageHeader>
  );
};

import { TFunction } from "i18next";

import { LoanRole } from "../../../common/enums/loan-role";

type Params = {
  t: TFunction<"translation", undefined>;
  loanRole?: LoanRole | null;
};

export const convertLoanRoleToTranslation = ({ t, loanRole }: Params) => {
  console.log(loanRole);
  if (!loanRole) {
    return null;
  }

  switch (loanRole) {
    case LoanRole.DEBTOR:
      return t("loans.labels.debtor");
    case LoanRole.CREDITOR:
      return t("loans.labels.creditor");
    default:
      return null;
  }
};

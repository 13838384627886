import Avatar from "@/components/avatar/avatar";
import AvatarFallback from "@/components/avatar/avatar-fallback";
import Card from "@/components/card/card";
import Column from "@/components/column";
import { Connection } from "@/domains/connections/interfaces/connection.interface";

interface Props {
  connection: Connection;
}

export const ConnectionCard = ({ connection }: Props) => {
  return (
    <Card className="flex size-full flex-col items-center p-4">
      <Column className="max-w-full items-center gap-1">
        <Avatar className="size-12">
          <AvatarFallback value={connection.username} />
        </Avatar>
        <div className="max-w-full truncate text-center text-lg font-medium">
          {connection.username}
        </div>
        <div className="max-w-full truncate text-center text-sm text-muted-foreground">
          {connection.email}
        </div>
        {connection.fullname && (
          <div className="max-w-full truncate text-center text-sm text-muted-foreground">
            {connection.fullname}
          </div>
        )}
      </Column>
    </Card>
  );
};

import { useTranslation } from "react-i18next";

import { RegisterForm } from "@/domains/auth/components/register-form/register-form";
import { AuthLayout } from "@/domains/core/components/layouts/auth-layout";

export const RegisterPage = () => {
  const { t } = useTranslation();
  return (
    <AuthLayout
      title={t("profile.sections.register.title")}
      description={t("profile.sections.register.description")}
    >
      <RegisterForm />
    </AuthLayout>
  );
};

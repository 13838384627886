import { Check, X } from "lucide-react";
import { useTranslation } from "react-i18next";

import Avatar from "@/components/avatar/avatar";
import AvatarFallback from "@/components/avatar/avatar-fallback";
import Button from "@/components/button/button";
import { Identity } from "@/domains/identities/interfaces/identity.interface";
import { getFullname } from "@/domains/identities/utils/get-fullname";
import { useAcceptLoan } from "@/domains/loans/hooks/use-accept-loan";
import { useDeclineLoan } from "@/domains/loans/hooks/use-decline-loan";
import { LoanAgreement } from "@/domains/loans/interfaces/loan-agreement";
import { Loan } from "@/domains/loans/interfaces/loan.interface";
import { convertLoanRoleToTranslation } from "@/domains/loans/utils/convert-loan-role-to-translation";
import { getAgreementStatus } from "@/domains/loans/utils/get-agreement-status";
import { getLoanRole } from "@/domains/loans/utils/get-loan-role";

import { AgreementStatus } from "../../../../common/enums/agreement-status";
import { LoanStatus } from "../../../../common/enums/loan-status.enum";

type Props = {
  identity: Identity;
  loan: Loan;
  agreements: LoanAgreement[];
};

export const AgreementBanner = ({ loan, identity, agreements }: Props) => {
  const { t } = useTranslation();

  const { handleAcceptLoan } = useAcceptLoan({});
  const { handleDeclineLoan } = useDeclineLoan({});

  const agreementStatus = getAgreementStatus({
    identity,
    agreements,
  });

  if (loan.status !== LoanStatus.PENDING) {
    return null;
  }

  if (agreementStatus !== AgreementStatus.PENDING) {
    return null;
  }

  const loanRole = getLoanRole({
    identityId: identity.id,
    creditors: loan.creditors,
    debtors: loan.debtors,
  });

  console.log(loanRole);

  return (
    <div className="space-y-4">
      <div className="rounded-lg bg-gray-100 p-4">
        <div className="mb-4 flex items-center justify-between">
          <div className="flex items-center">
            <Avatar className="size-10">
              <AvatarFallback value={identity?.username} />
            </Avatar>
            <div className="ml-3">
              <span className="font-medium">{getFullname(identity)}</span>
              <p className="text-sm text-muted-foreground">
                {convertLoanRoleToTranslation({ t, loanRole })}
              </p>
            </div>
          </div>
          <div className="flex gap-2">
            <Button
              size="sm"
              variant="default"
              onClick={() => handleAcceptLoan({ loanId: loan.id })}
            >
              <Check className="size-4 sm:mr-2" />
              <span className="hidden sm:inline">
                {t("agreements.actions.acceptTerms")}
              </span>
            </Button>
            <Button
              size="sm"
              variant="destructive"
              onClick={() => handleDeclineLoan({ loanId: loan.id })}
            >
              <X className="size-4 sm:mr-2" />
              <span className="hidden sm:inline">
                {t("agreements.actions.declineTerms")}
              </span>
            </Button>
          </div>
        </div>
        <p className="text-sm text-muted-foreground">
          {t("agreements.sections.banner.terms")}
        </p>
      </div>
    </div>
  );
};

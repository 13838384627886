import { z } from "zod";

export const transactionFormSchema = z.object({
  amount: z
    .number({
      coerce: true,
      required_error: "A valid number is required",
      invalid_type_error: "A valid number is required",
    })
    .nonnegative("Amount must be a positive number")
    .step(0.01, "Must be a number with 0.01 steps"),
  senderId: z.string(),
  receiverId: z.string(),
});

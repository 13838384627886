import { Navigate, createBrowserRouter } from "react-router-dom";

import { loanRoutes } from "@/routes/loans";
import { Authenticated } from "@/routes/parents/authenticated";
import { Public } from "@/routes/parents/public";

import { connectionRoutes } from "./connections";
import { identitiesRoutes } from "./identities";
import { profileRoutes } from "./profiles";
import { publicRoutes } from "./public";
import { Route } from "../common/enums/route.enum";

export const router = createBrowserRouter([
  { path: "/", element: <Navigate to={Route.loans} replace /> },
  {
    element: <Public />,
    children: [...publicRoutes],
  },
  {
    element: <Authenticated />,
    children: [
      ...profileRoutes,
      ...loanRoutes,
      ...connectionRoutes,
      ...identitiesRoutes,
    ],
  },
]);

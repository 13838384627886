import { Identity } from "@/domains/identities/interfaces/identity.interface";
import { LoanAgreement } from "@/domains/loans/interfaces/loan-agreement";

import { AgreementStatus } from "../../../common/enums/agreement-status";

type Params = {
  identity: Identity;
  agreements?: LoanAgreement[];
};

export const getAgreementStatus = ({ identity, agreements = [] }: Params) => {
  if (identity.isOwned) {
    return AgreementStatus.AGREED;
  }

  const agreement = agreements.find(
    (agreement) => agreement.identity.id === identity.id
  );

  if (!agreement) {
    return AgreementStatus.PENDING;
  }

  return agreement.agreed ? AgreementStatus.AGREED : AgreementStatus.CANCELLED;
};

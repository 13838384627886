import Column from "@/components/column";
import { CreateConnectionsCard } from "@/domains/connections/components/create-connections/create-connections-card";
import { useGetConnections } from "@/domains/connections/hooks/use-get-connections";
import { Pagination } from "@/domains/core/components/pagination";

import { ConnectionCard } from "./connection-card";

export const ConnectionsList = () => {
  const { data, pagination, setPagination } = useGetConnections();

  return (
    <Column className="w-full gap-2">
      <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
        <div className="min-h-40 sm:col-span-2 md:col-span-2 lg:col-span-2">
          <CreateConnectionsCard />
        </div>
        {data?.data.map((connection) => (
          <ConnectionCard key={connection.id} connection={connection} />
        ))}
      </div>
      <Pagination
        pagination={pagination}
        setPagination={setPagination}
        total={data?.total}
      />
    </Column>
  );
};

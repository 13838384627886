import cn from "@/utils/cn";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const Title = ({ children, className }: Props) => {
  return (
    <h1 className={cn("text-2xl font-bold tracking-tighter", className)}>
      {children}
    </h1>
  );
};

import { useParams } from "react-router-dom";

import Column from "@/components/column";
import Separator from "@/components/separator";
import Skeleton from "@/components/skeleton/skeleton";
import { AppLayout } from "@/domains/core/components/layouts/app-layout";
import { useGetMyIdentity } from "@/domains/identities/hooks/use-get-my-identity";
import { LoanTransactionsChart } from "@/domains/loans/components/charts/loan-transactions-chart";
import { LoanDetailHeader } from "@/domains/loans/components/loan-detail/loan-detail-header";
import { LoanDetails } from "@/domains/loans/components/loan-detail/loan-details";
import { ParticipantList } from "@/domains/loans/components/participants/participant-list";
import { TransactionList } from "@/domains/loans/components/transactions/transaction-list";
import { useGetLoan } from "@/domains/loans/hooks/use-get-loan";
import { useGetTransactionsSummary } from "@/domains/loans/hooks/use-get-transactions-summary";

export const LoanDetailPage = () => {
  const { id = "" } = useParams();

  const { data: identity } = useGetMyIdentity();
  const { data: loan } = useGetLoan({ loanId: id });
  const { data: transactionsSummary } = useGetTransactionsSummary({
    loanId: id,
  });

  if (!loan || !transactionsSummary || !identity) {
    return <Skeleton rowsCount={10} />;
  }

  return (
    <AppLayout header={<LoanDetailHeader loanId={id} />}>
      <Column className="max-w-3xl gap-4">
        <ParticipantList identity={identity} loan={loan} />
        <Separator className="my-8" />
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <LoanDetails loan={loan} />
          <LoanTransactionsChart
            loan={loan}
            transactionsSummary={transactionsSummary}
          />
        </div>
        <Separator className="my-8" />
        <TransactionList loan={loan} />
      </Column>
    </AppLayout>
  );
};

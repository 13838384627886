import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { PieChart } from "@/domains/core/components/charts/pie-chart";
import { Loan } from "@/domains/loans/interfaces/loan.interface";
import { TransactionsSummary } from "@/domains/loans/interfaces/transactions-summary";
import { convertToAnnualInterestRate } from "@/domains/loans/utils/convert-to-annual-interest-rate";
import { formatCurrency } from "@/domains/loans/utils/format-currency";

interface Props {
  loan: Loan;
  transactionsSummary: TransactionsSummary;
}

export const LoanTransactionsChart = ({ loan, transactionsSummary }: Props) => {
  const { t } = useTranslation();

  const chartData = useMemo(
    () => [
      {
        data: [
          {
            label: t("payments.tooltips.principal", {
              currency: loan.currency,
            }),
            value: transactionsSummary?.principalTotal,
            color: "pastel-green",
          },
          {
            label: t("payments.tooltips.interest", { currency: loan.currency }),
            value: transactionsSummary?.interestTotal,
            color: "pastel-red",
          },
        ],
        innerRadius: "69%",
        outerRadius: "84%",
      },
      {
        data: [
          {
            label: t("payments.tooltips.confirmed", {
              currency: loan.currency,
            }),
            value: transactionsSummary?.confirmedTotal,
            color: "pastel-blue",
          },
          {
            label: t("payments.tooltips.unconfirmed", {
              currency: loan.currency,
            }),
            value: transactionsSummary?.unconfirmedTotal,
            color: "pastel-yellow",
          },
          {
            label: t("payments.tooltips.remaining", {
              currency: loan.currency,
            }),
            value: transactionsSummary?.remainingTotal,
            color: "pastel-gray",
          },
        ],
        innerRadius: "85%",
        outerRadius: "100%",
      },
    ],
    [t, loan, transactionsSummary]
  );

  // const legendItems = useMemo(() => {
  //   return chartData.flatMap((layer) =>
  //     layer.data.map((item) => ({
  //       label: item.label,
  //       color: item.color,
  //     }))
  //   );
  // }, [chartData]);

  return (
    <div>
      <PieChart
        paddingAngle={1}
        startAngle={90}
        endAngle={-270}
        title={formatCurrency({
          amount: transactionsSummary?.owedTotal,
          currency: loan.currency,
        })}
        subtitle={`${convertToAnnualInterestRate({
          interestRate: loan.interestRate,
          interestPeriod: loan.interestPeriod,
        })}% p.a.`}
        layers={chartData}
      />
    </div>
  );
};

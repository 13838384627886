import { useTranslation } from "react-i18next";

import { ResetPasswordForm } from "@/domains/auth/components/reset-password-form";
import { AuthLayout } from "@/domains/core/components/layouts/auth-layout";

export const ResetPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout
      title={t("profile.sections.resetPassword.title")}
      description={t("profile.sections.resetPassword.description")}
    >
      <ResetPasswordForm />
    </AuthLayout>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import Button from "@/components/button/button";
import { useResetPassword } from "@/domains/auth/hooks/use-reset-password";
import { FormInput } from "@/domains/core/components/form/form-input";

import { useResetPasswordSchema } from "../hooks/use-reset-password-schema";

export const ResetPasswordForm = () => {
  const { t } = useTranslation();

  const schema = useResetPasswordSchema();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      identifier: "",
    },
  });

  const { handleResetPassword, isLoading } = useResetPassword();

  const onSubmit = ({ identifier }: z.infer<typeof schema>) => {
    handleResetPassword({ identifier });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormInput
          control={form.control}
          label={t("profile.labels.usernameOrEmail")}
          type="text"
          name="identifier"
        />
        <Button className="w-full" type="submit" disabled={isLoading}>
          {isLoading && <Loader2 className="mr-2 size-4 animate-spin" />}
          {t("profile.actions.sendLink")}
        </Button>
      </form>
    </FormProvider>
  );
};

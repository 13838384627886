import { useCallback } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

import { errorCodeToTranslation } from "../errors/error-code-to-translation";
import { ErrorCode } from "../errors/errors.enum";
import { ErrorResponse } from "../interfaces/error-response.interface";

export const useErrorTranslation = () => {
  const { t } = useTranslation();

  const getErrorMessage = useCallback(
    (errorCode: ErrorCode): string => {
      return errorCodeToTranslation({ errorCode, t });
    },
    [t]
  );

  const getStatusCodeMessage = useCallback(
    (statusCode: number): string => {
      console.log(statusCode);
      const key = `api.errors.http.${statusCode}`;
      return t(key, { defaultValue: t("api.errors.unknown") });
    },
    [t]
  );

  const translateError = useCallback(
    (error: unknown): string => {
      if (error instanceof AxiosError && error.response?.data) {
        const response = error.response.data as ErrorResponse;

        const statusCode = response.statusCode;
        const errorCode = response?.error?.code as ErrorCode;

        const isKnownError = Object.values(ErrorCode).includes(errorCode);

        if (isKnownError && errorCode) {
          return getErrorMessage(errorCode);
        }

        return getStatusCodeMessage(statusCode);
      }

      if (error instanceof Error) {
        return t("api.errors.unexpected", { message: error.message });
      }

      return t("api.errors.unknown");
    },
    [t, getErrorMessage, getStatusCodeMessage]
  );

  return { translateError };
};

import Column from "@/components/column";
import { Identity } from "@/domains/identities/interfaces/identity.interface";
import { useGetLoanAgreements } from "@/domains/loans/hooks/use-get-loan-agreements";
import { Loan } from "@/domains/loans/interfaces/loan.interface";

import { AgreementBanner } from "./agreement-banner";
import { ParticipantCard } from "./participant-card";

type Props = {
  loan: Loan;
  identity: Identity;
};

export const ParticipantList = ({ loan, identity }: Props) => {
  const { data } = useGetLoanAgreements({
    loanId: loan.id,
  });

  return (
    <Column className="gap-4">
      <AgreementBanner
        identity={identity}
        loan={loan}
        agreements={data?.data ?? []}
      />

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        {[...loan.creditors, ...loan.debtors].map((participant) => (
          <ParticipantCard
            key={participant.id}
            loan={loan}
            identity={participant}
            agreements={data?.data ?? []}
          />
        ))}
      </div>
    </Column>
  );
};

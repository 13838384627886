import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2, LockIcon, UserRoundIcon } from "lucide-react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { z } from "zod";

import Button from "@/components/button/button";
import { useLogin } from "@/domains/auth/hooks/use-login";
import { FormInput } from "@/domains/core/components/form/form-input";

import { Route } from "../../../common/enums/route.enum";
import { useLoginSchema } from "../hooks/use-login-schema";

export const LoginForm = () => {
  const { t } = useTranslation();

  const schema = useLoginSchema();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      identifier: "",
      password: "",
    },
  });

  const { handleLogin, isLoading } = useLogin();

  const onSubmit = ({ identifier, password }: z.infer<typeof schema>) => {
    handleLogin({ identifier, password });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormInput
          icon={UserRoundIcon}
          control={form.control}
          label={t("profile.labels.usernameOrEmail")}
          type="text"
          name="identifier"
        />
        <FormInput
          icon={LockIcon}
          control={form.control}
          label={t("profile.labels.password")}
          name={"password"}
          type="password"
        />
        <Link
          to={Route.resetPassword}
          className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
        >
          {t("profile.sections.login.forgottenPassword")}
        </Link>
        <Button className="w-full" type="submit" disabled={isLoading}>
          {isLoading && <Loader2 className="mr-2 size-4 animate-spin" />}
          {t("profile.actions.login")}
        </Button>
      </form>
      <div className="text-sm font-medium">
        <span>{t("profile.sections.login.dontHaveAccount")}</span>
        <Link
          to={Route.register}
          className="ml-1 text-blue-600 hover:underline dark:text-blue-500"
        >
          {t("profile.actions.register")}
        </Link>
      </div>
    </FormProvider>
  );
};

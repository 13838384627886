import { CoinsIcon, FileQuestionIcon, PercentIcon } from "lucide-react";

import { LoanTransaction } from "@/domains/loans/interfaces/loan-transaction";

import { TransactionType } from "../../../../common/enums/transaction-type";

type Props = {
  transaction: LoanTransaction;
};

export const TransactionTypeIcon = ({ transaction }: Props) => {
  switch (transaction.type) {
    case TransactionType.PAYMENT:
      return (
        <CoinsIcon className="size-8 rounded-full border-2 border-black p-1" />
      );
    case TransactionType.INTEREST:
      return (
        <PercentIcon className="size-8 rounded-full border-2 border-black p-1" />
      );
    default:
      return (
        <FileQuestionIcon className="size-8 rounded-full border-2 border-black p-1" />
      );
  }
};

import queryString from "query-string";

import { Identity } from "@/domains/identities/interfaces/identity.interface";
import { PaginatedData } from "@/interfaces/paginated-data.interface";

import identitiesInstance from "./identities.instance";

export const createMyIdentity = async ({
  firstName,
  lastName,
  address,
}: {
  firstName: string;
  lastName: string;
  address?: string;
}): Promise<void> => {
  await identitiesInstance.post<Identity>(`/me`, {
    firstName,
    lastName,
    address,
  });
};

export const createOwnedIdentity = async ({
  firstName,
  lastName,
  address,
}: {
  firstName: string;
  lastName: string;
  address?: string;
}): Promise<void> => {
  await identitiesInstance.post<Identity>(`/owned`, {
    firstName,
    lastName,
    address,
  });
};

export const getMyIdentity = async (): Promise<Identity> => {
  const response = await identitiesInstance.get<Identity>(`/me`);
  return response.data;
};

export const updateMyIdentity = async ({
  firstName,
  lastName,
  address,
}: {
  firstName: string;
  lastName: string;
  address?: string;
}): Promise<void> => {
  await identitiesInstance.patch<Identity>(`/me`, {
    firstName,
    lastName,
    address,
  });
};

export const getOwnedIdentities = async ({
  page,
  pageSize,
}: {
  page?: number;
  pageSize?: number;
}): Promise<PaginatedData<Identity>> => {
  const queryParams = queryString.stringify({
    page,
    pageSize,
  });
  const response = await identitiesInstance.get<PaginatedData<Identity>>(
    `/owned?${queryParams}`
  );
  return response.data;
};

export const getConnectionIdentities = async ({
  page,
  pageSize,
}: {
  page?: number;
  pageSize?: number;
}): Promise<PaginatedData<Identity>> => {
  const queryParams = queryString.stringify({
    page,
    pageSize,
  });
  const response = await identitiesInstance.get<PaginatedData<Identity>>(
    `/connections?${queryParams}`
  );
  return response.data;
};

export const getAllIdentities = async ({
  page,
  pageSize,
}: {
  page?: number;
  pageSize?: number;
}): Promise<PaginatedData<Identity>> => {
  const queryParams = queryString.stringify({
    page,
    pageSize,
  });
  const response = await identitiesInstance.get<PaginatedData<Identity>>(
    `/?${queryParams}`
  );
  return response.data;
};

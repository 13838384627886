import { create } from "zustand";
import { persist } from "zustand/middleware";

import { AuthStore } from "@/domains/auth/interfaces/auth-store.interface";

// STORE
export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      accessToken: "",
      refreshToken: "",
      setAccessToken: (accessToken) => set(() => ({ accessToken })),
      setRefreshToken: (refreshToken) => set(() => ({ refreshToken })),
    }),
    {
      name: "auth",
    }
  )
);

// SELECTORS
export const getAccessToken = () => useAuthStore.getState().accessToken;
export const setAccessToken = (accessToken: string | null) =>
  useAuthStore.getState().setAccessToken(accessToken);

export const getRefreshToken = () => useAuthStore.getState().refreshToken;
export const setRefreshToken = (refreshToken: string | null) =>
  useAuthStore.getState().setRefreshToken(refreshToken);

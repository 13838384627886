import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { confirmPassword } from "@/domains/auth/services/auth.api";

import { Route } from "../../../common/enums/route.enum";
import { useErrorTranslation } from "../../../common/hooks/use-error-translation";

export const useConfirmPassword = () => {
  const { t } = useTranslation();
  const { translateError } = useErrorTranslation();
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: async ({
      password,
      tokenId,
    }: {
      password: string;
      tokenId: string;
    }) => {
      await confirmPassword({ password, tokenId });
    },
    onSuccess: () => {
      navigate(Route.login);
      toast.success(t("common.toasts.general.success.title"));
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      toast.error(t("common.toasts.general.failure.title"), {
        description: translateError(error),
      });
    },
  });

  return {
    handleConfirmPassword: mutate,
    isLoading: isPending,
  };
};

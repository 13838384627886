import { useEffect, useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import Button from "@/components/button/button";
import { BankDetailsCarousel } from "@/domains/bank-details/components/bank-details-carousel";
import { FormInput } from "@/domains/core/components/form/form-input";
import { FormSelect } from "@/domains/core/components/form/form-select";
import { useGetLoan } from "@/domains/loans/hooks/use-get-loan";
import { useGetTransactionsSummary } from "@/domains/loans/hooks/use-get-transactions-summary";
import { useRepayLoan } from "@/domains/loans/hooks/use-repay-loan";
import { transactionFormSchema } from "@/domains/loans/schemas/transaction-schema";

interface Props {
  loanId: string;
  onSuccess?: () => void;
}

export const TransactionForm = ({ loanId, onSuccess }: Props) => {
  const { t } = useTranslation();

  const { data: loan } = useGetLoan({ loanId });
  const { data: transactionsSummary } = useGetTransactionsSummary({ loanId });

  const { handleRepayLoan, isLoading: isAddingPayment } = useRepayLoan({
    onSuccess,
  });

  const form = useForm<z.infer<typeof transactionFormSchema>>({
    resolver: zodResolver(transactionFormSchema),
    defaultValues: {
      amount: 0,
      senderId: "",
      receiverId: "",
    },
  });

  const amount = form.watch("amount");

  const onSubmit = (formData: z.infer<typeof transactionFormSchema>) => {
    if (!loan) return;

    handleRepayLoan({
      amount: formData.amount,
      senderId: formData.senderId,
      receiverId: formData.receiverId,
      loanId: loan.id,
    });
  };

  const payRest = () => {
    if (!loan || !transactionsSummary) return;

    form.setValue("amount", transactionsSummary.remainingTotal);
  };

  useEffect(() => {
    if (loan && transactionsSummary) {
      const firstDebtor = loan.debtors[0];
      const firstCreditor = loan.creditors[0];

      form.reset({
        amount: transactionsSummary.remainingTotal,
        senderId: firstDebtor.id,
        receiverId: firstCreditor.id,
      });
    }
  }, [form, loan, transactionsSummary]);

  const debtorOptions = useMemo(
    () =>
      loan?.debtors.map(({ id, username, firstName, lastName }) => ({
        value: id,
        name: username ? `${username}` : `${firstName} ${lastName}`,
      })) ?? [],
    [loan?.debtors]
  );

  const creditorOptions = useMemo(
    () =>
      loan?.creditors.map(({ id, username, firstName, lastName }) => ({
        value: id,
        name: username ? `${username}` : `${firstName} ${lastName}`,
      })) ?? [],
    [loan?.creditors]
  );

  const creditorsWithBankDetail = useMemo(
    () => loan?.creditors.filter(({ bankDetail }) => bankDetail?.iban) ?? [],
    [loan]
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-4">
        <div className="px-10">
          <BankDetailsCarousel
            creditors={creditorsWithBankDetail}
            amount={amount}
          />
        </div>
        <div className="flex w-full max-w-sm items-end space-x-2">
          <FormInput
            control={form.control}
            name={"amount"}
            type="number"
            step={0.01}
            label={t("payments.labels.amount")}
            className="flex-1"
          />
          <Button variant="secondary" onClick={payRest}>
            {t("payments.actions.payRemaining")}
          </Button>
        </div>
        <FormSelect
          control={form.control}
          name={"senderId"}
          label={t("payments.labels.payer")}
          options={debtorOptions}
          className="flex-1"
        />
        <FormSelect
          control={form.control}
          name={"receiverId"}
          label={t("payments.labels.receiver")}
          options={creditorOptions}
          className="flex-1"
        />
        <Button type="submit" disabled={isAddingPayment} className="w-full">
          {t("payments.actions.addPayment")}
        </Button>
      </form>
    </FormProvider>
  );
};

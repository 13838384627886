import { Navigate, Outlet } from "react-router-dom";

import { getRefreshToken } from "@/domains/auth/hooks/use-auth-store";

import { Route } from "../../common/enums/route.enum";

export const Public = () => {
  const refreshToken = getRefreshToken();

  return !refreshToken ? <Outlet /> : <Navigate to={Route.loans} replace />;
};

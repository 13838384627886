import queryString from "query-string";

import bankDetailsInstance from "./bank-details.instance";

export const getPaymentQRCode = async ({
  bankDetailId,
  amount,
  currency,
  dueDate,
  message,
  constantSymbol,
  specificSymbol,
  variableSymbol,
}: {
  bankDetailId: string;
  amount?: number;
  currency?: string;
  dueDate?: Date;
  message?: string;
  constantSymbol?: string;
  specificSymbol?: string;
  variableSymbol?: string;
}): Promise<string> => {
  const queryParams = queryString.stringify({
    amount,
    currency,
    dueDate,
    message,
    constantSymbol,
    specificSymbol,
    variableSymbol,
  });

  const response = await bankDetailsInstance.get<{ image: string }>(
    `/${bankDetailId}/payment?${queryParams}`
  );
  return response.data.image;
};

export const createBankDetail = async ({
  identityId,
  iban,
}: {
  identityId?: string;
  iban: string;
}): Promise<void> => {
  await bankDetailsInstance.post("", {
    identityId,
    iban,
  });
};

export const updateBankDetail = async ({
  bankDetailId,
  iban,
}: {
  bankDetailId: string;
  iban: string;
}): Promise<void> => {
  await bankDetailsInstance.put(`/${bankDetailId}`, {
    iban,
  });
};

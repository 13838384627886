import { AppLayout } from "@/domains/core/components/layouts/app-layout";
import { LoansHeader } from "@/domains/loans/components/my-loans/loans-header";
import { LoansList } from "@/domains/loans/components/my-loans/loans-list";

export const LoansPage = () => {
  return (
    <AppLayout header={<LoansHeader />}>
      <LoansList />
    </AppLayout>
  );
};

import {
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInQuarters,
  differenceInYears,
} from "date-fns";
import { TFunction } from "i18next";

import { InterestPeriod } from "../../../common/enums/interest-period.enum";

type Params = {
  t: TFunction<"translation", undefined>;
  interestPeriod: InterestPeriod;
  startDate?: Date;
  dueDate?: Date;
};

export const getFormattedDuration = ({
  t,
  interestPeriod,
  startDate,
  dueDate,
}: Params): string => {
  if (!startDate || !dueDate) {
    return t("loans.periods.years", {
      count: 0,
    });
  }

  switch (interestPeriod) {
    case InterestPeriod.DAILY:
      return t("loans.periods.days", {
        count: differenceInDays(dueDate, startDate),
      });
    case InterestPeriod.WEEKLY:
      return t("loans.periods.weeks", {
        count: differenceInWeeks(dueDate, startDate),
      });
    case InterestPeriod.MONTHLY:
      return t("loans.periods.months", {
        count: differenceInMonths(dueDate, startDate),
      });
    case InterestPeriod.QUARTERLY:
      return t("loans.periods.quarters", {
        count: differenceInQuarters(dueDate, startDate),
      });
    case InterestPeriod.ANNUALLY:
      return t("loans.periods.years", {
        count: differenceInYears(dueDate, startDate),
      });
  }
};

import { useTranslation } from "react-i18next";

import Avatar from "@/components/avatar/avatar";
import AvatarFallback from "@/components/avatar/avatar-fallback";
import { Identity } from "@/domains/identities/interfaces/identity.interface";
import { getFullname } from "@/domains/identities/utils/get-fullname";
import { LoanAgreement } from "@/domains/loans/interfaces/loan-agreement";
import { Loan } from "@/domains/loans/interfaces/loan.interface";
import { convertLoanRoleToTranslation } from "@/domains/loans/utils/convert-loan-role-to-translation";
import { getLoanRole } from "@/domains/loans/utils/get-loan-role";

import { AgreementBadge } from "./agreement-badge";

type Props = {
  loan: Loan;
  identity: Identity;
  agreements: LoanAgreement[];
};

export const ParticipantCard = ({ loan, identity, agreements }: Props) => {
  const { t } = useTranslation();

  const loanRole = getLoanRole({
    identityId: identity.id,
    creditors: loan.creditors,
    debtors: loan.debtors,
  });

  const fullname = getFullname(identity);

  return (
    <div className="flex items-center justify-between rounded-lg bg-gray-100  p-4">
      <div className="flex items-center">
        <Avatar className="size-8">
          <AvatarFallback value={fullname} />
        </Avatar>
        <div className="ml-3">
          <span className="text-sm font-medium">{fullname}</span>
          <p className="text-xs capitalize text-muted-foreground">
            {convertLoanRoleToTranslation({ t, loanRole })}
          </p>
        </div>
      </div>
      <AgreementBadge identity={identity} agreements={agreements} />
    </div>
  );
};

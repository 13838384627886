import { AlertTriangle } from "lucide-react";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

type Props = {
  errors: FieldErrors;
};

// TODO: get alert compoennts from shadcnui

export const ErrorSummary = ({ errors }: Props) => {
  const { t } = useTranslation();
  if (Object.keys(errors).length === 0) {
    return null;
  }

  return (
    <div className="mb-4 border-l-4 border-red-500 bg-red-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <AlertTriangle className="size-5 text-red-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {t("common.errors.errorSummary")}
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">
              {Object.entries(errors).map(([field, error]) => (
                <li key={field}>{String(error?.message)}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

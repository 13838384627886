import { ConnectionsPage } from "@/domains/connections/pages/connections";

import { Route } from "../common/enums/route.enum";

export const connectionRoutes = [
  {
    path: Route.connections,
    element: <ConnectionsPage />,
  },
];

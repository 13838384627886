export const identitiesQueryKeys = {
  all: () => ["identities"] as const,
  allPaginated: ({ page, pageSize }: { page: number; pageSize: number }) =>
    [...identitiesQueryKeys.all(), page, pageSize] as const,
  allOwned: () => [...identitiesQueryKeys.all(), "owned"] as const,
  allOwnedPaginated: ({ page, pageSize }: { page: number; pageSize: number }) =>
    [...identitiesQueryKeys.allOwned(), page, pageSize] as const,
  allConnected: () => [...identitiesQueryKeys.all(), "connected"] as const,
  allConnectedPaginated: ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => [...identitiesQueryKeys.allConnected(), page, pageSize] as const,
  me: () => [...identitiesQueryKeys.all(), "me"] as const,
};

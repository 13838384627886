import { Identity } from "@/domains/identities/interfaces/identity.interface";

export const getIdentityLabel = ({
  firstName,
  lastName,
  username,
}: Identity): string => {
  return username
    ? `${firstName} ${lastName} (${username})`
    : `${firstName} ${lastName}`;
};

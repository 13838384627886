import { useMemo, useState } from "react";

import { PlusCircle } from "lucide-react";
import { useTranslation } from "react-i18next";

import Button from "@/components/button/button";
import Column from "@/components/column";
import { Pagination } from "@/domains/core/components/pagination";
import { useGetLoanTransactions } from "@/domains/loans/hooks/use-get-loan-transactions";
import { Loan } from "@/domains/loans/interfaces/loan.interface";
import cn from "@/utils/cn";

import { TransactionCard } from "./transaction-card/transaction-card";
import { AddTransactionDialog } from "./transaction-form/add-transaction-dialog";
import { LoanStatus } from "../../../../common/enums/loan-status.enum";
import { BankDetailsCarousel } from "../../../bank-details/components/bank-details-carousel";

type Props = {
  loan: Loan;
};

export const TransactionList = ({ loan }: Props) => {
  const { t } = useTranslation();

  const [isOpen, open] = useState<boolean>(false);

  const { data, pagination, setPagination } = useGetLoanTransactions({
    loanId: loan.id,
  });

  const isActive = loan.status === LoanStatus.ACTIVE;

  const creditorsWithBankDetail = useMemo(
    () => loan.creditors.filter(({ bankDetail }) => bankDetail?.iban) ?? [],
    [loan]
  );

  const showBankDetails = creditorsWithBankDetail.length > 0;

  return (
    <Column>
      <div className="space-y-4">
        {isActive && (
          <div
            className={cn(
              "flex flex-col-reverse space-y-4 space-y-reverse",
              "md:flex-row md:space-x-4 md:space-y-0"
            )}
          >
            <Button
              variant="default"
              size="lg"
              className={cn(
                "h-auto w-full bg-gradient-to-br from-green-600 to-green-400 p-6 text-white shadow-lg transition-all duration-300 hover:scale-105 hover:from-green-700 hover:to-green-500 hover:shadow-2xl",
                showBankDetails ? "md:w-1/2" : "md:w-full"
              )}
              onClick={() => open(true)}
            >
              <div className="flex w-full flex-row items-center justify-between">
                <div className="text-left">
                  <h2 className="text-2xl font-bold">
                    {t("loans.sections.detail.transactions.ctaTitle")}
                  </h2>
                  <p>{t("loans.sections.detail.transactions.ctaSubtitle")}</p>
                </div>
                <PlusCircle className="ml-4 size-16" />
              </div>
            </Button>
            {showBankDetails && (
              <div
                className={cn(
                  "w-full rounded-lg bg-white p-4 shadow-md",
                  "md:w-1/2"
                )}
              >
                <BankDetailsCarousel
                  creditors={creditorsWithBankDetail}
                  amount={0}
                />
              </div>
            )}
          </div>
        )}
        {data?.data.map((transaction) => (
          <TransactionCard
            key={transaction.id}
            loan={loan}
            transaction={transaction}
          />
        ))}
        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          total={data?.total}
        />
      </div>
      <AddTransactionDialog
        loanId={loan.id}
        isOpen={isOpen}
        onClose={() => open(false)}
      />
    </Column>
  );
};

import { useTranslation } from "react-i18next";

import { PageHeader } from "@/domains/core/components/pages/page-header";
import { Title } from "@/domains/core/components/typography/title";

export const LoansHeader = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      title={<Title>{t("loans.sections.list.title")}</Title>}
    ></PageHeader>
  );
};

import { useTranslation } from "react-i18next";

import Badge from "@/components/badge/badge";
import { LoanTransaction } from "@/domains/loans/interfaces/loan-transaction";

import { ConfirmationStatus } from "../../../../common/enums/confirmation-status";
import { TransactionType } from "../../../../common/enums/transaction-type";

type Props = {
  transaction: LoanTransaction;
};

export const TransactionStatusBadge = ({ transaction }: Props) => {
  const { t } = useTranslation();

  if (transaction.type === TransactionType.INTEREST) {
    return (
      <Badge variant="outline" className="text-xs uppercase">
        {t("payments.enums.confirmationStatus.automatic")}
      </Badge>
    );
  }

  switch (transaction.confirmation?.status) {
    case ConfirmationStatus.CONFIRMED:
      return (
        <Badge variant="success" className="text-xs uppercase">
          {t("payments.enums.confirmationStatus.confirmed")}
        </Badge>
      );
    case ConfirmationStatus.CANCELLED:
      return (
        <Badge variant="destructive" className="uppercase">
          {t("payments.enums.confirmationStatus.cancelled")}
        </Badge>
      );
    default:
      return (
        <Badge variant="pending" className="uppercase">
          {t("payments.enums.confirmationStatus.pending")}
        </Badge>
      );
  }
};

export enum ErrorCode {
  // GENERIC
  VALIDATION_ERROR = "VALIDATION_ERROR",

  // AUTH
  AUTH_INVALID_CREDENTIALS = "AUTH_INVALID_CREDENTIALS",
  AUTH_RESET_TOKEN_EXPIRED = "AUTH_RESET_TOKEN_EXPIRED",
  AUTH_RESET_TOKEN_INVALID = "AUTH_RESET_TOKEN_INVALID",

  // IDENTITY
  IDENTITY_CONFLICT = "IDENTITY_CONFLICT",
  IDENTITY_NOT_FOUND = "IDENTITY_NOT_FOUND",

  // LOAN
  LOAN_NO_VALID_LOAN = "LOANS_NO_VALID_LOAN",
  LOAN_NOT_FOUND = "LOAN_NOT_FOUND",

  // TRANSACTION
  TRANSACTION_NOT_FOUND = "TRANSACTION_NOT_FOUND",
  TRANSACTION_EXCEEDING_AMOUNT = "TRANSACTION_EXCEEDING_AMOUNT",
  TRANSACTION_NOT_PART_OF = "TRANSACTION_NOT_PART_OF",

  // CONFIRMATION
  CONFIRMATION_ALREADY_EXISTS = "CONFIRMATION_ALREADY_EXISTS",

  // CONNECTION
  CONNECTION_NO_VALID_USERS = "CONNECTION_NO_VALID_USERS",

  // BANK DETAILS
  BANK_DETAIL_INVALID_IBAN = "BANK_DETAIL_INVALID_IBAN",

  // User errors
  USER_IDENTIFIER_CONFLICT = "USER_IDENTIFIER_CONFLICT",
  USER_NOT_FOUND = "USER_NOT_FOUND",
}

export const loansQueryKeys = {
  // LOANS
  all: () => ["loans"] as const,
  allPaginated: ({
    page,
    pageSize,
    sortBy,
  }: {
    page: number;
    pageSize: number;
    sortBy: string;
  }) => [...loansQueryKeys.all(), page, pageSize, sortBy] as const,
  detail: ({ id }: { id: string }) => [...loansQueryKeys.all(), id] as const,

  // AGREEMENTS
  agreementsAll: () => ["loans", "agreements"] as const,
  agreementsDetail: ({ id }: { id: string }) =>
    [...loansQueryKeys.agreementsAll(), id] as const,
  agreementsDetailPaginated: ({
    id,
    page,
    pageSize,
  }: {
    id: string;
    page: number;
    pageSize: number;
  }) => [...loansQueryKeys.agreementsAll(), id, page, pageSize] as const,

  // TRANSACTIONS
  transactionsAll: () => ["loans", "transactions"] as const,
  transactionsSummary: ({ id }: { id: string }) =>
    [...loansQueryKeys.transactionsAll(), "summary", id] as const,
  transactionDetail: ({ id }: { id: string }) =>
    [...loansQueryKeys.transactionsAll(), id] as const,
  transactionDetailPaginated: ({
    id,
    page,
    pageSize,
  }: {
    id: string;
    page: number;
    pageSize: number;
  }) => [...loansQueryKeys.transactionsAll(), id, page, pageSize] as const,
};

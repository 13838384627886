import { useTranslation } from "react-i18next";

import Avatar from "@/components/avatar/avatar";
import AvatarFallback from "@/components/avatar/avatar-fallback";
import Card from "@/components/card/card";
import CardContent from "@/components/card/card-content";

type Props = {
  fullname?: string;
  address?: string;
};

export const IdentityCard = ({ fullname, address }: Props) => {
  const { t } = useTranslation();

  return (
    <Card className="w-full">
      <CardContent className="flex flex-row items-center gap-4 p-4 md:flex-col md:gap-2 md:text-center">
        <Avatar className="size-10 shrink-0">
          <AvatarFallback value={fullname} />
        </Avatar>
        <div className="flex w-full flex-col items-start overflow-hidden md:items-center">
          <div className="w-full truncate text-center font-medium">
            {fullname}
          </div>
          <div className="w-full text-center text-sm text-muted-foreground">
            <p className="line-clamp-2 text-pretty">
              {address ?? t("identity.labels.addressEmpty")}
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

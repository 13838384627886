import { TFunction } from "i18next";
import { z } from "zod";

type Params = {
  t: TFunction<"translation", undefined>;
};

export const addressSchema = ({ t }: Params) => {
  return z.union([
    z
      .string()
      .min(3, {
        message: t("common.errors.fieldTooShort", {
          field: t("identity.labels.address"),
          count: 3,
        }),
      })
      .max(200, {
        message: t("common.errors.fieldTooLong", {
          field: t("identity.labels.address"),
          count: 200,
        }),
      }),
    z.string().max(0),
    z.undefined(),
  ]);
};

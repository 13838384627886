import { useEffect, useState } from "react";

import Skeleton from "@/components/skeleton/skeleton";
import { useGetPaymentQrCode } from "@/domains/bank-details/hooks/use-get-payment-qr-code";

interface Props {
  bankDetailId: string;
  amount?: number;
  currency?: string;
  dueDate?: Date;
  message?: string;
  constantSymbol?: string;
  specificSymbol?: string;
  variableSymbol?: string;
}

export const BankDetailQrCode = ({
  bankDetailId,
  amount,
  currency,
  dueDate,
  message,
  constantSymbol,
  specificSymbol,
  variableSymbol,
}: Props) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const { data: image } = useGetPaymentQrCode({
    bankDetailId,
    amount,
    currency,
    dueDate,
    message,
    constantSymbol,
    specificSymbol,
    variableSymbol,
  });

  useEffect(() => {
    if (image) {
      setImageSrc(image);
    }
  }, [image]);

  if (!imageSrc) {
    return <Skeleton rowsCount={5} />;
  }

  return <img src={imageSrc} className="size-full" />;
};

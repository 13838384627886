import { useQuery } from "@tanstack/react-query";

import { getLoanAgreements } from "@/domains/loans/services/loans.api";
import { loansQueryKeys } from "@/domains/loans/utils/query-keys";

type Params = {
  loanId: string;
};

export const useGetLoanAgreements = ({ loanId }: Params) =>
  useQuery({
    queryKey: loansQueryKeys.agreementsDetail({
      id: loanId,
    }),
    queryFn: async () =>
      getLoanAgreements({
        loanId,
      }),
  });

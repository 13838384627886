import * as React from "react";

import { VariantProps } from "class-variance-authority";

import cn from "@/utils/cn";

import variants from "./variants";

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof variants> {}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <div
        className={cn(variants({ variant }), className)}
        ref={ref}
        {...props}
      />
    );
  }
);

export default Badge;

import * as React from "react";

import { Command as CommandPrimitive } from "cmdk";
import { SearchXIcon, X } from "lucide-react";

import Avatar from "@/components/avatar/avatar";
import AvatarFallback from "@/components/avatar/avatar-fallback";
import AvatarImage from "@/components/avatar/avatar-image";
import Badge from "@/components/badge/badge";
import Command from "@/components/command/command";
import CommandGroup from "@/components/command/command-group";
import CommandItem from "@/components/command/command-item";
import CommandList from "@/components/command/command-list";

import Loader from "./loader";

export interface SelectableItem {
  id: string;
  label: string;
  imageUrl?: string;
}

interface MultiSelectSearchProps {
  data: SelectableItem[];
  isLoading: boolean;
  query: string;
  setQuery: (value: string) => void;
  selected: SelectableItem[];
  setSelected: React.Dispatch<React.SetStateAction<SelectableItem[]>>;
  withAvatar?: boolean;
  renderEmptyElement?: () => React.ReactElement;
}

const MultiSelectSearch = ({
  data,
  isLoading,
  query,
  setQuery,
  selected,
  setSelected,
  renderEmptyElement = () => <SearchXIcon className="size-4" />,
  withAvatar,
}: MultiSelectSearchProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleSelect = (incomingItems: SelectableItem[]) => {
    setQuery("");
    setSelected([...selected, ...incomingItems]);
  };

  const handleUnselect = (incomingItems: SelectableItem[]) => {
    const filtered = selected.filter((selectedItem) => {
      return !incomingItems.some(
        (incomingItem) => incomingItem.id === selectedItem.id
      );
    });
    setSelected(filtered);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (input) {
      if (e.key === "Delete" || e.key === "Backspace") {
        if (input.value === "") {
          const lastItem = selected[selected.length - 1];
          handleUnselect([lastItem]);
        }
      }
      if (e.key === "Escape") {
        input.blur();
      }
    }
  };

  const selectableItems = data.filter(
    (item) => !selected.some((selectedItem) => selectedItem.id === item.id)
  );

  return (
    <Command
      onKeyDown={handleKeyDown}
      className="overflow-visible bg-transparent"
      shouldFilter={false}
    >
      <div className="group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
        <div className="flex flex-wrap gap-1">
          {selected.map((item) => {
            return (
              <Badge key={item.id} variant="outline" className="p-1">
                {withAvatar && (
                  <Avatar className="mr-2 size-6">
                    <AvatarImage src={item.imageUrl} />
                    <AvatarFallback value={item.label} />
                  </Avatar>
                )}
                {item.label}
                <button
                  className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                  onClick={() => handleUnselect([item])}
                >
                  <X className="size-3 text-red-500 hover:text-black" />
                </button>
              </Badge>
            );
          })}
          <CommandPrimitive.Input
            ref={inputRef}
            value={query}
            onValueChange={setQuery}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            className="ml-2 flex-1 bg-transparent outline-none"
          />
        </div>
      </div>
      <div className="relative mt-2">
        {query.length > 0 && open && (
          <CommandList className="absolute top-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
            {isLoading && (
              <div className="m-4 flex items-center justify-center">
                <Loader />
              </div>
            )}
            {!isLoading && selectableItems.length === 0 && (
              <div className="m-4 flex items-center justify-center">
                {renderEmptyElement()}
              </div>
            )}
            {!isLoading && selectableItems.length > 0 && (
              <CommandGroup className="overflow-auto">
                {selectableItems.map((item) => (
                  <CommandItem
                    key={item.id}
                    value={item.label}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onSelect={() => handleSelect([item])}
                    className="cursor-pointer"
                  >
                    {withAvatar && (
                      <Avatar className="mr-2 size-8">
                        <AvatarImage src={item.imageUrl} />
                        <AvatarFallback value={item.label} />
                      </Avatar>
                    )}
                    <span>{item.label}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
            )}
          </CommandList>
        )}
      </div>
    </Command>
  );
};

export default MultiSelectSearch;

import { useTranslation } from "react-i18next";

import Dialog from "@/components/dialog/dialog";
import DialogContent from "@/components/dialog/dialog-content";
import { Subtitle } from "@/domains/core/components/typography/subtitle";
import { Title } from "@/domains/core/components/typography/title";

import { TransactionForm } from "./transaction-form";

interface Props {
  loanId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const AddTransactionDialog = ({ isOpen, loanId, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent>
        <div className="flex items-center justify-between py-5">
          <div className="space-y-2">
            <Title>{t("payments.sections.addPayment.title")}</Title>
            <Subtitle>{t("payments.sections.addPayment.description")}</Subtitle>
          </div>
        </div>
        <TransactionForm loanId={loanId} onSuccess={onClose} />
      </DialogContent>
    </Dialog>
  );
};

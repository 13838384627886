import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AvatarStack from "@/components/avatar/avatar-stack";
import Card from "@/components/card/card";
import Column from "@/components/column";
import Row from "@/components/row";
import Tooltip from "@/components/tooltip/tooltip";
import TooltipContent from "@/components/tooltip/tooltip-content";
import TooltipTrigger from "@/components/tooltip/tooltip-trigger";
import { LoanBadges } from "@/domains/loans/components/loan-badges";
import { LoanTimeline } from "@/domains/loans/components/loan-timeline";
import { Loan } from "@/domains/loans/interfaces/loan.interface";
import { convertInterestPeriodToTranslation } from "@/domains/loans/utils/convert-interest-period-to-translation";
import { convertToAnnualInterestRate } from "@/domains/loans/utils/convert-to-annual-interest-rate";
import { formatCurrency } from "@/domains/loans/utils/format-currency";

import { Route } from "../../../../common/enums/route.enum";

interface Props {
  loan: Loan;
}

export const LoanCard = ({ loan }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card
      className="w-full cursor-pointer p-4 shadow-lg transition-transform hover:scale-105 hover:shadow-xl"
      onClick={() => navigate(`${Route.loans}/${loan.id}`)}
    >
      <Column className="h-full justify-between">
        <Column className="gap-2">
          <Row className="w-full items-baseline justify-between">
            <AvatarStack
              avatars={[...loan.creditors, ...loan.debtors].map((user) => ({
                fallback: user.username,
              }))}
              maxItems={3}
            />
            <Row className="h-3 flex-wrap justify-end gap-x-2 gap-y-1">
              <LoanBadges loan={loan} />
            </Row>
          </Row>

          <Column className="gap-2">
            <div className="text-lg font-semibold">{loan.name}</div>
            <p className="line-clamp-3 text-sm text-gray-600">
              {loan.description}
            </p>
          </Column>
        </Column>

        <Column className="gap-2">
          <LoanTimeline loan={loan} />

          <Row className="w-full justify-between">
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="cursor-help">
                  <span className="mr-1 text-lg font-semibold">
                    {convertToAnnualInterestRate({
                      interestRate: loan.interestRate,
                      interestPeriod: loan.interestPeriod,
                    })}
                    %
                  </span>
                  <span className="text-sm text-gray-600">p.a</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                {loan.interestRate}%{" "}
                {convertInterestPeriodToTranslation({
                  t,
                  interestPeriod: loan.interestPeriod,
                })}
              </TooltipContent>
            </Tooltip>

            <div className="text-lg font-semibold">
              {formatCurrency({ amount: loan.amount, currency: loan.currency })}
            </div>
          </Row>
        </Column>
      </Column>
    </Card>
  );
};

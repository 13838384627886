import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import Button from "@/components/button/button";
import { useConfirmPassword } from "@/domains/auth/hooks/use-confirm-password";
import { FormInput } from "@/domains/core/components/form/form-input";

import { useNewPasswordSchema } from "../hooks/use-new-password-schema";

type Props = {
  tokenId: string;
};

export const NewPasswordForm = ({ tokenId }: Props) => {
  const { t } = useTranslation();

  const schema = useNewPasswordSchema();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const { handleConfirmPassword, isLoading } = useConfirmPassword();

  const onSubmit = async ({ password }: z.infer<typeof schema>) => {
    handleConfirmPassword({ tokenId, password });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormInput
          control={form.control}
          label={t("profile.labels.newPassword")}
          name={"password"}
          type="password"
        />
        <FormInput
          control={form.control}
          label={t("profile.labels.confirmPassword")}
          name={"confirmPassword"}
          type="password"
        />
        <Button className="w-full" type="submit" disabled={isLoading}>
          {isLoading && <Loader2 className="mr-2 size-4 animate-spin" />}
          {t("profile.actions.resetPassword")}
        </Button>
      </form>
    </FormProvider>
  );
};

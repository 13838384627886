import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { z } from "zod";

import { addressSchema } from "../../../common/schemas/address";
import { firstnameSchema } from "../../../common/schemas/firstname";
import { lastnameSchema } from "../../../common/schemas/lastname";

export const useIdentityFormSchema = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const schema = z.object({
      firstName: firstnameSchema({ t }),
      lastName: lastnameSchema({ t }),
      address: addressSchema({ t }),
    });

    return schema;
  }, [t]);
};

import { useTranslation } from "react-i18next";

import Tabs from "@/components/tabs/tabs";
import TabsContent from "@/components/tabs/tabs-content";
import TabsList from "@/components/tabs/tabs-list";
import TabsTrigger from "@/components/tabs/tabs-trigger";

import { PendingInvites } from "./pending-invites";
import { PendingRequests } from "./pending-requests";

const PENDING_INVITES = "invites";
const PENDING_REQUESTS = "requests";

export const PendingConnections = () => {
  const { t } = useTranslation();

  return (
    <Tabs defaultValue={PENDING_INVITES} className="w-full gap-2">
      <TabsList className="grid grid-cols-2">
        <TabsTrigger value={PENDING_INVITES}>
          {t("connections.sections.pendingInvites.tabTitle")}
        </TabsTrigger>
        <TabsTrigger value={PENDING_REQUESTS}>
          {t("connections.sections.pendingRequests.tabTitle")}
        </TabsTrigger>
      </TabsList>
      <TabsContent value={PENDING_INVITES}>
        <PendingInvites />
      </TabsContent>
      <TabsContent value={PENDING_REQUESTS}>
        <PendingRequests />
      </TabsContent>
    </Tabs>
  );
};

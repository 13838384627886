import cn from "@/utils/cn";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const Subtitle = ({ children, className }: Props) => {
  return <p className={cn("text-gray-500", className)}>{children}</p>;
};

import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { PaginationState, SortingState } from "@tanstack/react-table";


import { getMyLoans } from "@/domains/loans/services/loans.api";
import { loansQueryKeys } from "@/domains/loans/utils/query-keys";
import {
  getServerPagination,
  DEFAULT_PAGE_INDEX,
  getServerSorting,
  DEFAULT_PAGE_SIZE,
} from "@/utils/data-browsing";

export const useGetLoans = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const { page, pageSize } = getServerPagination(pagination);
  const sortBy = getServerSorting(sorting);

  const result = useQuery({
    queryKey: loansQueryKeys.allPaginated({
      page,
      pageSize,
      sortBy,
    }),
    queryFn: () => getMyLoans({ pagination: { page, pageSize }, sortBy }),
  });

  return {
    ...result,
    pagination,
    setPagination,
    sorting,
    setSorting,
  };
};

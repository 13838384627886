import { useState } from "react";

import { UseQueryResult } from "@tanstack/react-query";
import { Control, FieldValues, Path } from "react-hook-form";
import { useDebounce } from "use-debounce";

import FormControl from "@/components/form/form-control";
import FormField from "@/components/form/form-field";
import FormItem from "@/components/form/form-item";
import FormMessage from "@/components/form/form-message";
import Label from "@/components/label";
import MultiSelectSearch from "@/components/multiselect-search";
import { PaginatedData } from "@/interfaces/paginated-data.interface";
import cn from "@/utils/cn";

interface Props<TFieldValues extends FieldValues, T> {
  name: Path<TFieldValues>;
  control: Control<TFieldValues>;
  useQuery: (options: {
    query?: string;
    pageSize?: number;
  }) => UseQueryResult<PaginatedData<T>, Error>;
  getKey: (item: T) => string;
  formatLabel: (item: T) => string;
  label?: string;
  className?: string;
  pageSize?: number;
  withAvatar?: boolean;
  renderEmptyElement?: () => React.ReactElement;
}

export const FormMultiSelect = <TFieldValues extends FieldValues, T>({
  name,
  control,
  useQuery,
  getKey,
  formatLabel,
  label,
  className,
  pageSize = 5,
  withAvatar = true,
  renderEmptyElement,
}: Props<TFieldValues, T>) => {
  const [query, setQuery] = useState<string>("");
  const [debouncedQuery] = useDebounce(query, 500);
  const { data, isLoading } = useQuery({
    query: debouncedQuery,
    pageSize,
  });

  const mappedData = data?.data ?? [];

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn("", className)}>
          {label && <Label>{label}</Label>}
          <FormControl>
            <MultiSelectSearch
              data={mappedData.map((item) => ({
                id: getKey(item),
                label: formatLabel(item),
              }))}
              isLoading={isLoading}
              query={query}
              setQuery={setQuery}
              selected={field.value}
              setSelected={field.onChange}
              withAvatar={withAvatar}
              renderEmptyElement={renderEmptyElement}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

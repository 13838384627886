import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useLoanSchema } from "@/domains/loans/schemas/loan-schema";

import { IdentitySelector } from "./identities/identity-selector";
import { StepHeader } from "./step-header";

export const CreditorsStep = () => {
  const { t } = useTranslation();
  const { step1Schema } = useLoanSchema();

  const { watch } = useFormContext<z.infer<typeof step1Schema>>();

  const creditors = watch("creditors");

  return (
    <div className="space-y-8">
      <StepHeader
        title={t("loans.sections.create.creditors.title")}
        description={t("loans.sections.create.creditors.description")}
      />

      <IdentitySelector name="creditors" selectedParticipants={creditors} />
    </div>
  );
};

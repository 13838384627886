export const connectionsQueryKeys = {
  // CONNECTIONS
  all: () => ["connections"] as const,
  allPaginated: ({ page, pageSize }: { page: number; pageSize: number }) =>
    [...connectionsQueryKeys.all(), page, pageSize] as const,

  // REQUESTED CONNECTIONS
  requestedAll: () => ["connections", "requested"] as const,
  requestedAllPaginated: ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => [...connectionsQueryKeys.requestedAll(), page, pageSize] as const,

  // RECEIVED CONNECTIONS
  receivedAll: () => ["connections", "received"] as const,
  receivedAllPaginated: ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => [...connectionsQueryKeys.receivedAll(), page, pageSize] as const,
};

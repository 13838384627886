import { useMemo } from "react";

import { differenceInDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useGetMyIdentity } from "@/domains/identities/hooks/use-get-my-identity";
import { convertToAnnualInterestRate } from "@/domains/loans/utils/convert-to-annual-interest-rate";

import { Currency } from "../../../common/enums/currency.enum";
import { InterestPeriod } from "../../../common/enums/interest-period.enum";

export const useLoanSchema = () => {
  const { t } = useTranslation();

  const { data: myIdentity } = useGetMyIdentity();

  return useMemo(() => {
    // Step 1: Creditors
    const step1Schema = z.object({
      creditors: z
        .array(
          z.object({
            id: z.string(),
            fullname: z.string().optional(),
            address: z.string().optional(),
          })
        )
        .min(1, t("loans.errors.noCreditors")),
    });

    // Step 2: Creditors + Debtors
    const step2Schema = z
      .intersection(
        step1Schema,
        z.object({
          debtors: z
            .array(
              z.object({
                id: z.string(),
                fullname: z.string().optional(),
                address: z.string().optional(),
              })
            )
            .min(1, t("loans.errors.noDebtors")),
        })
      )
      .refine(
        (data) => {
          const creditorIds = new Set(data.creditors.map((c) => c.id));
          const debtorIds = new Set(data.debtors.map((d) => d.id));
          return ![...creditorIds].some((id) => debtorIds.has(id));
        },
        {
          message: t("loans.errors.sharedCreditorDebtor"),
          path: ["debtors"],
        }
      )
      .refine(
        (data) => {
          if (!myIdentity) return false;
          const allParticipants = [...data.creditors, ...data.debtors];
          return allParticipants.some(
            (participant) => participant.id === myIdentity.id
          );
        },
        {
          message: t("loans.errors.userNotParticipating"),
          path: ["debtors"],
        }
      );

    // Step 3: Creditors + Debtors + Purpose
    const step3Schema = z.intersection(
      step2Schema,
      z.object({
        name: z
          .string({
            required_error: t("common.errors.fieldRequired", {
              field: t("loans.labels.name"),
            }),
          })
          .min(1),
        description: z.string().optional(),
      })
    );

    // Step 4: Creditors + Debtors + Purpose + Terms
    const step4Schema = z
      .intersection(
        step3Schema,
        z.object({
          amount: z
            .number({
              required_error: t("common.errors.fieldRequired", {
                field: t("loans.labels.amountDue"),
              }),
              invalid_type_error: t("loans.errors.invalidAmount"),
            })
            .nonnegative(t("loans.errors.negativeAmount"))
            .step(0.01, t("loans.errors.amountPrecision")),
          currency: z.nativeEnum(Currency, {
            required_error: t("common.errors.fieldRequired", {
              field: t("loans.labels.currency"),
            }),
          }),
          interestRate: z
            .number({
              required_error: t("common.errors.fieldRequired", {
                field: t("loans.labels.interestRate"),
              }),
            })
            .nonnegative(t("loans.errors.negativeInterestRate"))
            .step(0.01, t("loans.errors.interestRatePrecision")),
          interestPeriod: z.nativeEnum(InterestPeriod, {
            required_error: t("common.errors.fieldRequired", {
              field: t("loans.labels.interestPeriod"),
            }),
          }),
          startDate: z.coerce.date({
            invalid_type_error: t("common.errors.fieldRequired", {
              field: t("loans.labels.startDate"),
            }),
            required_error: t("common.errors.fieldRequired", {
              field: t("loans.labels.startDate"),
            }),
          }),
          dueDate: z.coerce
            .date({
              invalid_type_error: t("common.errors.fieldRequired", {
                field: t("loans.labels.dueDate"),
              }),
              required_error: t("common.errors.fieldRequired", {
                field: t("loans.labels.dueDate"),
              }),
            })
            .refine((val) => val > new Date(), {
              message: t("loans.errors.pastDueDate"),
            }),
        })
      )
      .refine(
        (data) => {
          const annualRate = convertToAnnualInterestRate({
            interestRate: data.interestRate,
            interestPeriod: data.interestPeriod,
          });
          return annualRate <= 40;
        },
        {
          message: t("loans.errors.excessiveAnnualRate"),
          path: ["interestRate"],
        }
      )
      .refine(
        (data) => {
          return differenceInDays(data.dueDate, data.startDate) >= 1;
        },
        {
          message: t("loans.errors.startDateTooCloseToEndDate"),
          path: ["startDate"],
        }
      );

    // Step 5: All (same as step 4 in this case)
    const fullSchema = step4Schema;

    return {
      step1Schema,
      step2Schema,
      step3Schema,
      step4Schema,
      fullSchema,
    };
  }, [t, myIdentity]);
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

import { sendRequests } from "@/domains/connections/services/connections.api";

import { useErrorTranslation } from "../../../common/hooks/use-error-translation";

type Params = {
  onSuccess?: () => void;
};

export const useSendRequests = ({ onSuccess }: Params) => {
  const { t } = useTranslation();
  const { translateError } = useErrorTranslation();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: async ({ userIds }: { userIds: string[] }) => {
      return sendRequests({ userIds });
    },
    onSuccess: async () => {
      toast.success(t("common.toasts.general.success.title"));
      await queryClient.invalidateQueries();
      onSuccess?.();
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      toast.error(t("common.toasts.general.failure.title"), {
        description: translateError(error),
      });
    },
  });

  return {
    handleSendRequests: mutate,
    isLoading: isPending,
  };
};

import { DesktopNavigation } from "@/domains/core/components/navigation/desktop-navigation";
import { MobileNavigation } from "@/domains/core/components/navigation/mobile-navigation";

type Props = {
  header?: React.ReactNode;
  children: React.ReactNode;
};

export const AppLayout = ({ header, children }: Props) => {
  return (
    <div className="relative flex min-h-screen flex-col bg-background md:flex-row">
      <aside className="block shrink-0 border-b md:hidden">
        <MobileNavigation />
      </aside>
      <aside className="sticky top-0 hidden max-h-screen shrink-0 border-r md:block">
        <DesktopNavigation />
      </aside>
      <main className="flex-1">
        {header}
        <div className="flex flex-col gap-6 px-6 py-3">{children}</div>
      </main>
    </div>
  );
};

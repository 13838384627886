import { useMemo } from "react";

import { differenceInDays, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import Badge from "@/components/badge/badge";
import Tooltip from "@/components/tooltip/tooltip";
import TooltipContent from "@/components/tooltip/tooltip-content";
import TooltipTrigger from "@/components/tooltip/tooltip-trigger";
import { Loan } from "@/domains/loans/interfaces/loan.interface";

import { LoanStatus } from "../../../common/enums/loan-status.enum";

interface Props {
  loan: Loan;
}

export const LoanBadges = ({ loan }: Props) => {
  const { t } = useTranslation();

  const badges = useMemo(() => {
    const badges = [];

    switch (loan.status) {
      case LoanStatus.ACTIVE:
        badges.push(
          <Badge
            key={t("loans.enums.loanStatus.active")}
            variant="success"
            className="uppercase"
          >
            {t("loans.enums.loanStatus.active")}
          </Badge>
        );
        break;
      case LoanStatus.PENDING:
        badges.push(
          <Badge
            key={t("loans.enums.loanStatus.pending")}
            variant="pending"
            className="uppercase"
          >
            {t("loans.enums.loanStatus.pending")}
          </Badge>
        );
        break;
      case LoanStatus.CANCELLED:
        badges.push(
          <Badge
            key={t("loans.enums.loanStatus.cancelled")}
            variant="destructive"
            className="uppercase"
          >
            {t("loans.enums.loanStatus.cancelled")}
          </Badge>
        );
        break;
      case LoanStatus.PAID:
        badges.push(
          <Badge
            key={t("loans.enums.loanStatus.paid")}
            variant="info"
            className="uppercase"
          >
            {t("loans.enums.loanStatus.paid")}
          </Badge>
        );
        break;
      default:
        break;
    }

    if (loan.isOverdue) {
      const overdueInDays = differenceInDays(
        parseISO(loan.dueDate),
        parseISO(loan.startDate)
      );

      badges.push(
        <Tooltip>
          <TooltipTrigger asChild>
            <Badge
              key={t("loans.enums.loanStatus.overdue")}
              variant="destructive"
              className="cursor-help uppercase"
            >
              {t("loans.enums.loanStatus.overdue")}
            </Badge>
          </TooltipTrigger>
          <TooltipContent>
            {t("loans.tooltips.daysOverdue", { count: overdueInDays })}
          </TooltipContent>
        </Tooltip>
      );
    }

    return badges;
  }, [loan, t]);

  return <>{badges}</>;
};

import { PlusCircleIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Card from "@/components/card/card";
import Column from "@/components/column";
import { Pagination } from "@/domains/core/components/pagination";
import { useGetLoans } from "@/domains/loans/hooks/use-get-loans";

import { LoanCard } from "./loan-card";
import { Route } from "../../../../common/enums/route.enum";


export const LoansList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, pagination, setPagination } = useGetLoans();

  return (
    <Column className="w-full gap-2">
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        <Card
          className="flex w-full cursor-pointer flex-row items-center justify-center rounded-xl bg-gradient-to-br from-indigo-900 to-indigo-700 p-6 text-white shadow-lg transition-all duration-300 hover:scale-105 hover:shadow-2xl"
          onClick={() => navigate(`${Route.loans}/create`)}
        >
          <div>
            <h2 className="text-2xl font-bold">
              {t("loans.sections.list.ctaTitle")}
            </h2>
            <p>{t("loans.sections.list.ctaSubtitle")}</p>
          </div>
          <PlusCircleIcon className="size-16" />
        </Card>
        {data?.data.map((loan) => (
          <LoanCard key={loan.id} loan={loan} />
        ))}
      </div>
      <Pagination
        pagination={pagination}
        setPagination={setPagination}
        total={data?.total}
      />
    </Column>
  );
};

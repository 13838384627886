import { setDefaultOptions } from "date-fns";
import { useTranslation } from "react-i18next";

import Select from "@/components/select/select";
import SelectContent from "@/components/select/select-content";
import SelectGroup from "@/components/select/select-group";
import SelectItem from "@/components/select/select-item";
import SelectTrigger from "@/components/select/select-trigger";
import SelectValue from "@/components/select/select-value";
import { getAccessToken } from "@/domains/auth/hooks/use-auth-store";
import { dateLocales } from "@/domains/core/date-locales";
import { getLocale, setLocale } from "@/domains/core/hooks/use-settings-store";
import { useProfileUpdate } from "@/domains/users/hooks/use-update-profile";

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const { handleProfileUpdate } = useProfileUpdate({});

  const handleValueChanged = (value: string) => {
    i18n.changeLanguage(value);
    setLocale(value);
    setDefaultOptions({ locale: dateLocales[value] });

    const token = getAccessToken();

    if (token) {
      handleProfileUpdate({ locale: value });
    }
  };

  return (
    <div className="grid w-full items-center gap-1.5">
      <Select onValueChange={handleValueChanged} value={getLocale()}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder={t("common.actions.select")} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="en">English</SelectItem>
            <SelectItem value="cs">Česky</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

import { Link } from "react-router-dom";

import Avatar from "@/components/avatar/avatar";
import AvatarFallback from "@/components/avatar/avatar-fallback";
import { useGetMe } from "@/domains/users/hooks/use-get-me";

export const Profile = () => {
  const { data } = useGetMe();

  return (
    <Link to="/profile" className="flex items-center space-x-4">
      <Avatar>
        <AvatarFallback value={data?.email} />
      </Avatar>
      <div className="grid gap-0.5 text-sm">
        <div className="font-medium">
          <b>{data?.username ?? "{no username}"}</b>
        </div>
        <div className="text-gray-500 dark:text-gray-400">{data?.email}</div>
      </div>
    </Link>
  );
};

import { UsersRoundIcon } from "lucide-react";
import { useTranslation } from "react-i18next";

import Avatar from "@/components/avatar/avatar";
import AvatarFallback from "@/components/avatar/avatar-fallback";
import Button from "@/components/button/button";
import Checkbox from "@/components/checkbox";
import cn from "@/utils/cn";

import { Identity } from "../../../../identities/interfaces/identity.interface";
import { getFullname } from "../../../../identities/utils/get-fullname";

type Props = {
  identity: Identity;
  isSelected: boolean;
  toggle: ({
    id,
    fullname,
    address,
  }: {
    id: string;
    fullname: string;
    address?: string;
  }) => void;
};

export const IdentityButton = ({ identity, isSelected, toggle }: Props) => {
  const { t } = useTranslation();
  const fullname = getFullname(identity);

  const handleToggle = () =>
    toggle({
      id: identity.id,
      fullname: fullname,
      address: identity.address,
    });

  return (
    <div className="relative size-full">
      <Checkbox
        checked={isSelected}
        className="absolute left-2 top-2"
        onClick={handleToggle}
      />
      {!identity.isOwned && (
        <UsersRoundIcon className="absolute right-2 top-2 size-4 text-gray-400" />
      )}
      <Button
        type="button"
        variant="outline"
        className={cn(
          "w-full h-full flex flex-col items-center gap-1 hover:bg-accent hover:text-accent-foreground",
          isSelected && "bg-gray-100"
        )}
        onClick={handleToggle}
      >
        <Avatar className="size-10">
          <AvatarFallback value={fullname} />
        </Avatar>
        <div className="w-full truncate text-center text-base font-medium">
          {fullname}
        </div>
        <div className="w-full text-center text-sm text-muted-foreground">
          <p className="line-clamp-2	text-pretty">
            {identity.address ?? t("identity.labels.addressEmpty")}
          </p>
        </div>
      </Button>
    </div>
  );
};

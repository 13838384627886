import { useQuery } from "@tanstack/react-query";

import { getLoan } from "@/domains/loans/services/loans.api";
import { loansQueryKeys } from "@/domains/loans/utils/query-keys";

type Params = {
  loanId: string;
};

export const useGetLoan = ({ loanId }: Params) =>
  useQuery({
    queryKey: loansQueryKeys.detail({ id: loanId }),
    queryFn: () => getLoan({ loanId }),
  });

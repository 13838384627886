import { AuthResponse } from "@/domains/auth/interfaces/auth-response.interface";

import authInstance from "./auth.instance";

export const register = async ({
  username,
  email,
  password,
}: {
  username: string;
  email: string;
  password: string;
}): Promise<AuthResponse> => {
  const response = await authInstance.post<AuthResponse>(`/register`, {
    username,
    email,
    password,
  });
  return response.data;
};

export const login = async ({
  identifier,
  password,
}: {
  identifier: string;
  password: string;
}): Promise<AuthResponse> => {
  const response = await authInstance.post<AuthResponse>(`/login`, {
    identifier,
    password,
  });
  return response.data;
};

export const refresh = async ({
  token,
}: {
  token: string;
}): Promise<AuthResponse> => {
  const response = await authInstance.post<AuthResponse>(`/refresh`, {
    token,
  });
  return response.data;
};

export const signout = async ({ token }: { token: string }): Promise<void> => {
  await authInstance.post(`/signout`, {
    token,
  });
};

export const resetPassword = async ({
  identifier,
}: {
  identifier: string;
}): Promise<void> => {
  await authInstance.post(`/reset-password`, {
    identifier,
  });
};

export const confirmPassword = async ({
  tokenId,
  password,
}: {
  tokenId: string;
  password: string;
}): Promise<void> => {
  await authInstance.post(`/confirm-password`, {
    tokenId,
    password,
  });
};
